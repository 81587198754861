import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import getLocalLink from '../../../utils/getLocalLink';

import spin from '../../../assets/ui/spin.svg';
import logoLeclerc from '../../../assets/ui/landingPartner/logo-partner-leclerc.svg';
import logoOcLeclerc from '../../../assets/ui/landingPartner/logo-oc-x-leclerc.svg';

import GetRecontactedForm from './GetRecontactedForm';

function Form() {
  const [firstFormData, setFirstFormData] = useState({});
  const [isFirstFormSubmitted, setIsFirstFormSubmitted] = useState(false);
  const [isSecondFormSubmitted, setIsSecondFormSubmitted] = useState(false);
  const [isGetRecontacted, setGetRecontacted] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const { partner } = useLoaderData();

  const { post, response, loading } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partner.id}/contact`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    try {
      await post('landing', data);

      if (response.ok) {
        const finalFirstFormData = { ...response.data, ...data };
        setFirstFormData(finalFirstFormData);
        setIsFirstFormSubmitted(true);
        searchParams.set('email', finalFirstFormData.email);
        setSearchParams(searchParams);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const onSubmitSecondForm = async (data) => {
    const finalData = { ...firstFormData, ...data };
    try {
      await post('landing-two', finalData);

      if (response.ok) {
        setIsSecondFormSubmitted(true);
        setFirstFormData({});
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <div id="contact-form" className="parent-header tw-bg-white tw-bg-gradient-openclimat">
      <div id="content" className="tw-container tw-mx-auto tw-flex tw-flex-col xl:tw-flex-row tw-bg-white tw-text-white xl:tw-h-[720px] tw-relative tw-px-4 xl:tw-px-0">
        <div className="xl:tw-w-8/12 clip-65 xl:tw-bg-gradient-openclimat tw-flex tw-items-center xl:tw-pr-80 tw-py-[60px] tw-px-[14px] xl:tw-px-[80px]">
          {isSecondFormSubmitted && (
            <div>
              <h5 className="tw-text-3xl">
                {t('LANDING_LECLERC.FORM.TEXT_C')}
              </h5>
              <div className="tw-mt-[30px] tw-text-base">
                {t('LANDING_LECLERC.FORM.TEXT_D')}
              </div>
            </div>
          )}
          {!isSecondFormSubmitted && (
            <div className="tw-text-center xl:tw-text-left">
              <div className="tw-flex tw-justify-center xl:tw-justify-normal">
                <img src={logoOcLeclerc} alt="OpenClimat X Leclerc" className="tw-w-full tw-h-full md:tw-w-96 tw-object-contain xl:tw-object-none xl:tw-w-max xl:tw-h-max" />
              </div>
              <h5 className="tw-text-3xl tw-mt-9">
                {t('LANDING_LECLERC.FORM.TEXT_A')}
              </h5>
              <p className="tw-mt-[30px] tw-text-base tw-mb-0">
                {t('LANDING_LECLERC.FORM.TEXT_T')}
              </p>
            </div>
          )}
        </div>
        <div className="xl:tw-bg-white xl:tw-w-4/12 xl:tw-relative xl:tw-flex xl:tw-justify-center xl:tw-items-center">
          {!isFirstFormSubmitted && !isSecondFormSubmitted && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="tw-bg-white tw-pt-[30px] xl:tw-w-[640px] xl:tw-right-[80px] xl:tw-absolute tw-rounded-lg xl:tw-rounded-xl xl:tw-border xl:tw-border-solid xl:tw-border-greySix xl:tw-px-10 xl:tw-pt-[30px] xl:tw-pb-[30px] tw-text-greyOne tw-px-[30px] tw-pb-[30px]"
          >
            <div className="tw-flex tw-gap-6 xl:tw-gap-5 tw-w-full tw-flex-col xl:tw-flex-row">
              <div className="xl:tw-w-full tw-text-sm">
                <label htmlFor="email" className="tw-text-greyThree">
                  {t('LANDING_LECLERC.FORM.INPUT.EMAIL.LABEL')}
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className={`tw-w-full tw-rounded-md tw-border tw-border-solid  tw-px-3.5 tw-py-3 tw-mt-1 tw-text-greyFour ${errors.email ? 'tw-border-redError focus:tw-outline-redError' : 'tw-border-greySix focus:tw-outline-secondarySkyBlue'}`}
                  {...register('email', {
                    required: t('LANDING_LECLERC.FORM.INPUT.EMAIL.REQUIRED'),
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: t('LANDING_LECLERC.FORM.INPUT.EMAIL.INVALID'),
                    },
                  })}
                />
                {errors && errors.email && (
                <div className="tw-mt-1.5 tw-text-sm tw-text-redError">{errors.email?.message}</div>
                )}
              </div>
            </div>
            <div className="tw-flex tw-gap-2.5 tw-mt-4 tw-items-center">
              <input
                type="checkbox"
                name="accept"
                id="accept"
                {...register('accept', {
                  required: t('LANDING_LECLERC.FORM.INPUT.ACCEPT.REQUIRED'),
                })}
              />
              <label htmlFor="accept" className="tw-text-sm tw-text-primaryNewBlue tw-mt-1">
                {t('LANDING_LECLERC.FORM.TEXT_F')}
                <a href={`${getLocalLink('/invitation-adhesion-fournisseurs/politique-de-confidentialite')}`} className="tw-underline tw-text-primaryNewBlue" rel="nofollow noreferrer" target="blank">
                  {t('LANDING_LECLERC.FORM.TEXT_G')}
                </a>
              </label>
            </div>
            {errors && errors.accept && (
            <div className="tw-mt-1.5 tw-text-sm tw-text-redError">{errors.accept?.message}</div>
            )}
            <div className="tw-mt-10 xl:tw-mt-[18px]">
              <button type="submit" disabled={loading} className={`tw-w-full tw-py-3 tw-font-medium tw-bg-primaryNewBlue tw-text-white tw-border-0 tw-rounded-md ${loading ? 'disabled' : ''}`}>
                {loading ? <img src={spin} alt="spin" /> : t('LANDING_LECLERC.FORM.CONTINUE')}
              </button>
            </div>
          </form>
          )}
          {isFirstFormSubmitted && !isSecondFormSubmitted && !isGetRecontacted && (
            <div className="tw-bg-white tw-py-[30px] xl:tw-w-[640px] xl:tw-right-[80px] xl:tw-absolute tw-rounded-lg xl:tw-rounded-xl xl:tw-border xl:tw-border-solid xl:tw-border-greySix xl:tw-px-10 xl:tw-py-[50px] tw-text-greyOne tw-px-[30px] tw-mb-[60px] xl:tw-mb-0">
              <div className="">
                <h5 className="tw-font-normal text-center tw-text-xl tw-text-greyOne tw-mb-0">
                  {t('LANDING_LECLERC.FORM.TEXT_M')}
                </h5>
                <p className="tw-mt-2.5 tw-mb-0 tw-text-sm tw-text-greyThree tw-text-center">
                  {t('LANDING_LECLERC.FORM.TEXT_N')}
                </p>
                <div className="tw-flex tw-justify-center tw-mt-5">
                  <a
                    href="https://meetings-eu1.hubspot.com/meetings/elsachai/meet-with-openclimat"
                    className="tw-bg-secondarySkyBlue hover:tw-bg-secondarySkyBlueHover tw-py-2.5 tw-px-[50px] tw-border-0 tw-rounded tw-text-white tw-font-medium tw-no-underline"
                    target="blank"
                  >
                    {t('LANDING_LECLERC.FORM.TEXT_Q')}
                  </a>
                </div>
                <div className="tw-my-[50px] tw-font-bold tw-text-greyFour tw-text-xs tw-flex tw-w-full tw-gap-2.5 xl:tw-px-10">
                  <div className="tw-w-full tw-border-greySix tw-border-solid tw-border-t-0 tw-border-x-0 tw-h-[1px] tw-self-center tw-border-[1px]" />
                  <div>
                    {t('LANDING_LECLERC.FORM.OR')}
                  </div>
                  <div className="tw-w-full tw-border-greySix tw-border-solid tw-border-t-0 tw-border-x-0 tw-h-[1px] tw-self-center tw-border-[1px]" />
                </div>
                <h5 className="tw-text-xl tw-text-greyOne tw-text-center tw-font-normal">
                  {t('LANDING_LECLERC.FORM.TEXT_O')}
                </h5>
                <p className="tw-mt-2.5 tw-mb-0 tw-text-sm tw-text-greyThree tw-text-center">
                  {t('LANDING_LECLERC.FORM.TEXT_S')}
                </p>
                <div className="tw-flex tw-justify-center tw-mt-5">
                  <button
                    type="button"
                    className="tw-bg-primaryNewBlue hover:tw-bg-primaryNewBlueHover tw-p-2.5 tw-border-0 tw-w-[250px] tw-rounded tw-text-white tw-font-medium tw-no-underline"
                    onClick={() => setGetRecontacted(true)}
                  >
                    {t('LANDING_LECLERC.FORM.SEND')}
                  </button>
                </div>
              </div>
            </div>
          )}
          {isFirstFormSubmitted && !isSecondFormSubmitted && isGetRecontacted && (
            <GetRecontactedForm
              handleSubmit={handleSubmit}
              onSubmitSecondForm={onSubmitSecondForm}
              register={register}
              errors={errors}
              loading={loading}
              setGetRecontacted={setGetRecontacted}
            />
          )}
          {isFirstFormSubmitted && isSecondFormSubmitted && (
            <div className="xl:tw-relative tw-bg-white tw-p-10 xl:tw-p-0 tw-flex tw-justify-center xl:tw-justify-normal">
              <img src={logoLeclerc} alt="Logo Leclerc" className="xl:tw-absolute xl:-tw-left-[320px] xl:-tw-top-32 tw-h-full tw-w-full md:tw-w-96 xl:tw-h-max xl:tw-w-max tw-object-contain" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Form;
