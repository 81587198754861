import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/database/arrows-down-up.svg';
import minus from '../../../../assets/ui/minus.svg';

import toggleSort from '../../../../utils/toggleSort';
import formatTargetSBTSimple from '../../../../utils/formatTargetSbtSimple';
import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function SbtTargetSimpleHead({ filter, setFilter, sort, setSort, cellStyle }) {
  const { setLoading } = useContext(TableLoadingContext);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const SORT_NAME = 'sbt';

  useEffect(() => {
    if (searchParams.get('sbt')) {
      setSort(searchParams.get('sbt'));
    }
  }, []);

  return (
    <th className="font-12 tw-px-3 text-center tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySix tw-border" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME, setLoading)} style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        {t('SBT_TARGET_DETAILED')}
        {filter === SORT_NAME ? (
          <SortArrow sort={sort} />
        ) : (
          <span className="text-end tw-ml-2.5 align-self-center">
            <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
          </span>
        )}
      </div>
    </th>
  );
}

function SbtTargetSimpleBody({ profile, cellStyle, onClick }) {
  const { t } = useTranslation();
  return (
    <td className="tw-h-[60px] text-center tw-border-solid tw-border-greySix tw-border" style={cellStyle}>
      <Link to={onClick(profile)} className="tw-px-3 tw-no-underline tw-text-greyOne tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center">
        {profile.target_sbt && profile.target_sbt !== 5 ? t(formatTargetSBTSimple(profile.target_sbt)) : <img src={minus} alt="minus" />}
      </Link>
    </td>
  );
}

export { SbtTargetSimpleHead, SbtTargetSimpleBody };
