import React from 'react';
import { useTranslation } from 'react-i18next';

import logoOcLeclerc from '../../../assets/ui/landingPartner/logo-oc-x-leclerc.svg';
import logoLeclerc from '../../../assets/ui/landingPartner/logo-partner-leclerc.svg';

function Header() {
  const { t } = useTranslation();

  return (
    <header className="parent-header tw-bg-white xl:tw-bg-gradient-openclimat">
      <div id="content" className="tw-container tw-mx-auto tw-flex tw-flex-col xl:tw-flex-row tw-bg-white tw-text-white">
        <div className="xl:tw-w-3/5 clip-45 tw-px-[30px] tw-pt-11 tw-pb-14 xl:tw-pl-20 xl:tw-pr-44 xl:tw-pt-[60px] xl:tw-pb-52 tw-bg-gradient-openclimat">
          <div className="tw-flex tw-justify-center xl:tw-justify-normal">
            <img src={logoOcLeclerc} alt="OpenClimat X Leclerc" className="tw-w-full tw-h-full md:tw-w-96 tw-object-contain xl:tw-object-none xl:tw-w-max xl:tw-h-max" />
          </div>
          <h1 className="tw-text-[40px] xl:tw-text-4xl tw-mt-[70px] xl:tw-mt-16 tw-font-medium 2xl:tw-pr-20">
            {t('LANDING_LECLERC.HEADER.TITLE')}
          </h1>
          <h2 className="tw-text-lg tw-mt-[40px] xl:tw-mt-9 tw-font-normal tw-mb-16">
            {t('LANDING_LECLERC.HEADER.TEXT_A')}
            <br />
            <br />
            <span>{t('LANDING_LECLERC.HEADER.TEXT_B_A')}</span> {t('LANDING_LECLERC.HEADER.TEXT_B_B')}
            <br />
            {t('LANDING_LECLERC.HEADER.TEXT_C')}
          </h2>
          <a href="#contact-form" className="tw-bg-white tw-w-full xl:tw-w-fit tw-text-center tw-block tw-rounded-md tw-px-7 tw-py-2.5 tw-text-primaryNewBlue tw-no-underline text-sm hover:tw-bg-backgroundCleanBlue">
            {t('LANDING_LECLERC.HEADER.CTA_A')}
          </a>
        </div>
        <div className="tw-bg-white xl:tw-w-2/5 xl:tw-relative xl:tw-flex xl:tw-justify-center xl:tw-items-center">
          <a href="#contact-form" className="tw-top-8 tw-right-12 tw-hidden xl:tw-block tw-absolute tw-bg-secondarySkyBlue hover:tw-bg-secondarySkyBlueHover tw-rounded-md tw-px-7 tw-py-2.5 tw-text-white tw-no-underline text-sm">
            {t('LANDING_LECLERC.HEADER.CTA_B')}
          </a>
          <div className="xl:tw-relative tw-p-10 xl:tw-p-0 tw-flex tw-justify-center xl:tw-justify-normal">
            <img src={logoLeclerc} alt="Logo Leclerc" className="xl:tw-absolute xl:-tw-left-[320px] xl:-tw-top-32 tw-h-full tw-w-full md:tw-w-96 xl:tw-h-max xl:tw-w-max tw-object-contain" />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
