import React, { useContext, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/database/arrows-down-up.svg';
import minus from '../../../../assets/ui/minus.svg';

import toggleSort from '../../../../utils/toggleSort';
import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function CarbonWeightHead({ filter, setFilter, sort, setSort, partner, cellStyle }) {
  const { setLoading } = useContext(TableLoadingContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const SORT_NAME = 'partner_carbon_weight';

  useEffect(() => {
    if (searchParams.get(SORT_NAME)) {
      setSort(searchParams.get(SORT_NAME));
    }
  }, []);

  return (
    <th className="font-12 tw-px-3 tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySix tw-border tw-border-b-0" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME, setLoading)} style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        <div className="tw-flex tw-flex-col tw-justify-center">
          <div className="tw-flex tw-items-center">
            {t('CARBON_WEIGHT_AT')} {partner.name}
            {filter === SORT_NAME ? (
              <SortArrow sort={sort} />
            ) : (
              <span className="text-end tw-ml-2.5 align-self-center">
                <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
              </span>
            )}
          </div>
        </div>
      </div>
    </th>
  );
}

function CarbonWeightBody({ profile, cellStyle, onClick }) {
  const { i18n } = useTranslation();

  return (
    <td className="tw-h-[60px] tw-border-solid tw-border-greySix tw-border" style={cellStyle}>
      <Link to={onClick(profile)} className="tw-px-3 tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center tw-no-underline tw-text-greyOne">
        <div className="tw-text-sm tw-font-moskauGrotesk tw-font-normal tw-justify-center tw-flex">
          <div>
            {profile.carbon_weight ? `${(profile.carbon_weight / 1000).toLocaleString(i18n.language === 'fr' ? 'fr-FR' : 'en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ktCO2e` : (
              <img src={minus} alt="minus" />
            )}
          </div>
        </div>
      </Link>
    </td>
  );
}

export { CarbonWeightHead, CarbonWeightBody };
