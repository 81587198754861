import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function CategoryHead({ categoryLevel0, cellStyle }) {
  const { t } = useTranslation();

  return (
    <th className="font-12 tw-px-3 tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySix tw-border tw-border-b-0" style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        {categoryLevel0 ? (
          categoryLevel0.name
        ) : (
          t('CATEGORY_LEVEL_0')
        )}
      </div>
    </th>
  );
}

function CategoryBody({ profile, cellStyle, onClick }) {
  const [categories, setCategories] = useState('');
  const [categoriesFull, setCategoriesFull] = useState('');

  useEffect(() => {
    if (profile && profile.categories) {
      let categories = '';

      const categoriesSorted = profile?.categories.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      categoriesSorted.forEach((element, index) => {
        categories += `${element.name}${index + 1 < profile.categories.length ? ', ' : ''}`;
      });

      setCategoriesFull(categories);
      if (categories.length > 40) {
        categories = `${categories.substring(0, 40)}...`;
      }
      setCategories(categories);
    } else {
      setCategories('--');
    }
  }, [profile]);

  return (
    <td className="tw-group tw-border-solid tw-border-greySix tw-border tw-relative tw-h-[60px]" style={cellStyle}>
      <Link to={onClick(profile)} className="tw-px-3 tw-h-full tw-w-full tw-flex tw-items-center tw-no-underline tw-text-greyOne">
        {categories}
        {categoriesFull.length > 40 ? (
          <span className=" tw-max-w-96 tw-z-10 tw-absolute tw-hidden group-hover:tw-block tw-p-2 tw-bg-greyTwo tw-text-white  tw-bottom-3/4 tw-animate-fadeIn tw-text-xs tw-rounded-sm">
            {categoriesFull}
          </span>
        ) : ('')}
      </Link>
    </td>
  );
}

export { CategoryHead, CategoryBody };
