import React from 'react';
import { useTranslation } from 'react-i18next';
import checkWhite from '../../../../assets/ui/check-white.svg';
import leftArrow from '../../../../assets/ui/arrow-left-greyFour.svg';
import CategoryButton from './CategoryButton';

function FilterDropdownContent({
  isOpen,
  navigationPath,
  currentCategories,
  selectedCategoryId,
  onBack,
  onSelectAll,
  onCategorySelect,
  currentLevel,
  levels,
}) {
  const { t } = useTranslation();
  return (
    <div
      className={`tw-absolute -tw-top-1 tw-w-full tw-mt-1 tw-z-50 tw-transition-all tw-duration-200 tw-overflow-hidden tw-rounded-[6px] tw-bg-white tw-border tw-border-solid tw-border-greySix ${
        isOpen ? 'tw-visible max-h-[400px]' : 'tw-invisible max-h-0'
      } tw-py-2 tw-px-3`}
    >
      <div className="tw-flex tw-flex-col tw-text-xs">

        {navigationPath.length > 0 && (
          <div className="tw-py-3 tw-pl-2.5 tw-flex tw-items-center tw-border-b tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-greySeven">
            <button
              type="button"
              onClick={onBack}
              className="tw-mr-2.5 tw-border-none tw-bg-white"
            >
              <img src={leftArrow} alt="Back" width={20} />
            </button>
            <span className="tw-text-primaryNewBlue tw-font-moskauGrotesk tw-font-medium tw-text-sm">
              {navigationPath[navigationPath.length - 1].categoryName}
            </span>
          </div>
        )}

        {navigationPath.length > 0 ? (
          <button
            type="button"
            className="tw-px-4 tw-py-3 tw-w-full tw-flex tw-justify-between tw-text-xs tw-text-left tw-border-b tw-border-t-0 tw-border-x-0 tw-border-solid tw-border-b-greySeven tw-text-greyTwo tw-bg-white hover:tw-bg-greyEight"
            onClick={onSelectAll}
          >
            <div className="tw-w-44 tw-text-left tw-text-sm tw-text-primaryNewBlue tw-font-moskauGrotesk tw-font-medium">
              {t('PARTNER_DASHBOARD.STATISTICS.FILTER.ENTIRE_CATEGORY')}
            </div>
            <div
              className={`tw-min-w-4 tw-min-h-4 tw-flex tw-items-center tw-justify-center tw-rounded-full ${
                selectedCategoryId === navigationPath[navigationPath.length - 1].categoryId
                  ? 'tw-bg-primaryNewBlue'
                  : 'tw-border tw-border-solid tw-border-greyFour'
              }`}
            >
              {selectedCategoryId === navigationPath[navigationPath.length - 1].categoryId && (
              <img src={checkWhite} alt="Check" width={10} />
              )}
            </div>
          </button>
        ) : (
          <div className="tw-px-4 tw-py-3 tw-w-full tw-flex tw-justify-between tw-border-b tw-border-t-0 tw-border-x-0 tw-border-solid tw-border-b-greySeven tw-bg-white tw-text-sm tw-text-greyFour tw-font-moskauGrotesk tw-font-base">
            {t('PARTNER_DASHBOARD.STATISTICS.FILTER.CATEGORY_CHOOSE')}
          </div>
        )}

        {/* Categories list */}
        {currentCategories.map((category) => (
          <CategoryButton
            key={category.id}
            category={category}
            currentLevel={currentLevel}
            levels={levels}
            selectedCategoryId={selectedCategoryId}
            onClick={() => onCategorySelect(category)}
          />
        ))}
      </div>
    </div>
  );
}

export default FilterDropdownContent;
