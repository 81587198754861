import React, { createContext, useState } from 'react';

export const TableLoadingContext = createContext();

export function TableLoadingProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [navigationLoading, setNavigationLoading] = useState(false);

  return (
    <TableLoadingContext.Provider value={{ loading, setLoading, navigationLoading, setNavigationLoading }}>
      {children}
    </TableLoadingContext.Provider>
  );
}
