const checkPasswordStrenght = (password) => {
  // The string must contain at least 1 lowercase alphabetical character
  // The string must contain at least 1 uppercase alphabetical character
  // The string must contain at least 1 numeric character
  // The string must contain at least one special character
  // The string must be 12 characters or longer
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&?*])(?=.{12,})/.test(password);
};

export default checkPasswordStrenght;
