import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import circleQuestion from '../../../../assets/ui/circle-question.svg';
import circleQuestionScope3 from '../../../../assets/ui/circle-question-scope-3.svg';
import circleQuestionGrey from '../../../../assets/ui/circle-question-grey.svg';
import crossIconGrey from '../../../../assets/ui/cross-icon-grey.svg';
import calendarCrossBlue from '../../../../assets/ui/calendar-cross-blue.svg';
import calendarCheckBlue from '../../../../assets/ui/calendar-check-blue.svg';

import getCarbonFootprintDistribution from '../../../../utils/getCarbonFootprintDistribution';
import getUserLocal from '../../../../utils/getUserLocal';
import CarbonFootprintDistributionPie from './Chart/CarbonFootprintDistributionPie';
import loadBootstrapTooltips from '../../../../utils/loadBootstrapTooltips';
import maturityInfo from '../../../../utils/maturityInfo';
import getCarbonFootprintLevel from '../../../../utils/getCarbonFootprintLevel';
import AdvancementCircle from '../MaturityScale/AdvancementCircle';

function CarbonFootprintDistribution({ profile }) {
  const [comment, setComment] = useState();
  const [carbonFootprint, setCarbonFootprint] = useState();
  const [carbonFootprintDistribution, setCarbonFootprintDistribution] = useState();
  const [fromParentCompany, setFromParentCompany] = useState();
  const [carbonFootprintYear, setCarbonFootprintYear] = useState();
  const [chartData, setChartData] = useState([]);
  const [chartDataFiltered, setChartDataFiltered] = useState([]);
  const [totalCarbonFootprintValue, setTotalCarbonFootprintValue] = useState(0);
  const [formattedTotalCarbonFootprintValue, setFormattedTotalCarbonFootprintValue] = useState('');
  const [isPmeWithCommitment, setIsPmeWithCommitment] = useState(false);
  const [commitmentDate, setCommitmentDate] = useState(null);
  const [plannedPublicationDate, setPlannedPublicationDate] = useState(null);
  const [isPlannedPublicationDateValid, setIsPlannedPublicationDateValid] = useState(false);
  const [displayTable, setDisplayTable] = useState(false);
  const [isControledPmeWithoutCarbonFootprint] = useState(profile.is_managed && carbonFootprintDistribution?.length > 0 && profile.type === 2);

  const { t, i18n } = useTranslation();

  const toggleTable = (e) => {
    e.preventDefault();
    setDisplayTable(!displayTable);
  };

  const getPercentage = (partialValue, totalValue) => Math.round((100 * partialValue) / totalValue);

  const chartDataBuilder = (totalcarbonFootprintValue, carbonFootprint, carbonFootprintDistribution, isControledPmeWithoutCarbonFootprint, t) => {
    const colorsScope12 = ['#21AFFF', '#21AFFF', '#21AFFF', '#21AFFF'];
    const colorsScope3 = ['#031356', '#112787', '#5675C3'];
    const defaultColorScope3 = '#B3B6DA';
    const defaultNamingData = ['Scope 1 & 2', t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_12')];
    let data = [];
    let dataFiltered = [];

    if ((carbonFootprint && carbonFootprint[0] && carbonFootprint[1]) || isControledPmeWithoutCarbonFootprint) {
      if (carbonFootprintDistribution.length > 0) {
        carbonFootprintDistribution.sort((a, b) => b.scope - a.scope).sort((a, b) => {
          if (a.scope === 3 && b.scope === 3) {
            return b.percent - a.percent;
          }
          return true;
        });
        let scope3acc = 0;
        let scope12acc = 0;

        data = carbonFootprintDistribution.sort((a, b) => b.percent - a.percent).map((data) => {
          if (data.scope === 12) {
            scope12acc += 1;
          } else {
            scope3acc += 1;
          }

          let color;
          if (data.scope === 12) {
            color = colorsScope12[scope12acc - 1];
          } else {
            color = scope3acc <= 3 ? colorsScope3[scope3acc - 1] : defaultColorScope3;
          }

          return {
            scope: Number(data.scope),
            value: data.percent,
            color,
            id: data.name,
          };
        });
      } else {
        data = carbonFootprint.map((data, index) => ({
          value: getPercentage(data, totalcarbonFootprintValue),
          color: index <= 0 ? colorsScope12[0] : colorsScope3[0],
          id: defaultNamingData[index],
          scope: defaultNamingData[index] === 'Scope 1 & 2' ? 12 : 3,
        }));
      }
    } else if (carbonFootprint && (carbonFootprint[0])) {
      data = [{
        value: 10,
        color: '#21AFFF',
        id: t('DATA_NOT_AVAILABLE'),
        scope: 3,
      }];
    } else if (carbonFootprint && (carbonFootprint[1])) {
      data = [{
        value: 10,
        color: '#000D85',
        id: t('DATA_NOT_AVAILABLE'),
        scope: 3,
      }];
    } else {
      data = [{
        value: 100,
        color: '#F4F4F4',
        id: t('DATA_NOT_AVAILABLE'),
        scope: 3,
      }];
    }

    const totalPercent = data.reduce((acc, item) => acc + Number(item.value), 0);

    if (totalPercent.toFixed(1) < 100) {
      data = [
        ...data,
        {
          color: '#F4F4F4',
          id: t('NOT_COMMUNICATED'),
          scope: 3,
          value: (100 - totalPercent) % 1 !== 0 ? (100 - totalPercent).toFixed(1) : 100 - totalPercent,
        },
      ];
    }

    let scope12total = 0;
    let scopeAcc = 0;
    let scope3other = 0;

    if ((carbonFootprint && carbonFootprint[0] && carbonFootprint[1]) || isControledPmeWithoutCarbonFootprint) {
      data.map((item) => {
        if (item.scope === 12) {
          scopeAcc += parseFloat(item.value);
          scope12total += parseFloat(item.value);
        }
        return true;
      });

      dataFiltered = [
        {
          scope: 12,
          value: Math.ceil(scope12total),
          color: '#21AFFF',
          id: t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_10'),
        },
      ];

      const dataScope3 = data.filter((item) => item.scope === 3);

      dataScope3.map((item, index) => {
        if (dataScope3.length > 4) {
          if (index >= 0 && index < 3 && item.id !== t('NOT_COMMUNICATED')) {
            scopeAcc += parseFloat(item.value);
            dataFiltered.push(
              {
                scope: 3,
                value: Math.ceil(parseFloat(item.value)),
                color: item.color,
                id: item.id,
              },
            );
          }
          if (index >= 3 && item.id !== t('NOT_COMMUNICATED')) {
            scope3other += parseFloat(item.value);
          }
          if (item.id === t('NOT_COMMUNICATED')) {
            scope3other += parseFloat(item.value);
          }
        } else {
          if (index >= 0 && index < 4 && item.id !== t('NOT_COMMUNICATED')) {
            scopeAcc += parseFloat(item.value);
            dataFiltered.push(
              {
                scope: 3,
                value: Math.ceil(parseFloat(item.value)),
                color: item.color,
                id: item.id,
              },
            );
          }
          if (item.id === t('NOT_COMMUNICATED')) {
            scope3other += parseFloat(item.value);
          }
        }
        return true;
      });

      if (scopeAcc < 100) {
        dataFiltered.push(
          {
            scope: 3,
            value: Math.ceil(scope3other),
            color: '#B3B6DA',
            id: t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_11'),
          },
        );
      }
    } else {
      dataFiltered = data;
    }
    return [data, dataFiltered];
  };

  const getCarbonFootprintComment = (data) => {
    if (data) {
      if ((data.carbon_footprint[0] && data.carbon_footprint[1]) || data.carbon_footprint_distribution.length > 0) {
        return t('CARBON_FOOTPRINT_DISTRIBUTION.COMMENT.ONE');
      }
      if (data.carbon_footprint[0] || data.carbon_footprint[1]) {
        return t('CARBON_FOOTPRINT_DISTRIBUTION.COMMENT.TWO');
      }
      if (isPmeWithCommitment) {
        return t('CARBON_FOOTPRINT_DISTRIBUTION.COMMENT.THREE');
      }
      return t('CARBON_FOOTPRINT_DISTRIBUTION.COMMENT.THREE');
    }
    return t('CARBON_FOOTPRINT_DISTRIBUTION.COMMENT.THREE');
  };

  useEffect(() => {
    getCarbonFootprintDistribution(profile.id, getUserLocal(), profile.type, profile.parent_company_id).then((response) => {
      let totalCarbon;
      if ((response.carbon_footprint[0] && response.carbon_footprint[1]) && response.carbon_footprint.reduce((acc, cur) => acc + cur) < 1) {
        totalCarbon = response.carbon_footprint ? response.carbon_footprint.reduce((acc, cur) => acc + cur).toFixed(3) : 0;
      } else {
        totalCarbon = (response.carbon_footprint[0] && response.carbon_footprint[1]) ? response.carbon_footprint.reduce((acc, cur) => acc + cur).toFixed(1) : 0;
      }
      setCarbonFootprint(response.carbon_footprint);
      setCarbonFootprintDistribution(response.carbon_footprint_distribution);
      setFromParentCompany(response.from_parent_company);
      setCarbonFootprintYear(response.carbon_mix_balance_year);
      setTotalCarbonFootprintValue(response.carbon_footprint[0] + response.carbon_footprint[1]);
      const [data, dataFiltered] = chartDataBuilder(totalCarbon, response.carbon_footprint, response.carbon_footprint_distribution, isControledPmeWithoutCarbonFootprint, t);
      setChartData(data);
      setChartDataFiltered(dataFiltered);
      setIsPmeWithCommitment(profile.type === 2 && response?.commitment_date && response?.planned_publication_date);

      // if the planned_publication_date is not passed then IsPlannedPublicationDateValid is true
      setIsPlannedPublicationDateValid(new Date(response?.planned_publication_date) > new Date());
      setPlannedPublicationDate(new Date(response?.planned_publication_date).toLocaleString(i18n.language === 'fr' ? 'fr-FR' : 'en-GB', {
        month: 'long',
        year: 'numeric',
      }));
      setCommitmentDate(new Date(response?.commitment_date).toLocaleString(i18n.language === 'fr' ? 'fr-FR' : 'en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }));

      setComment(getCarbonFootprintComment(response));

      let formattedValue = '';

      const emissionsScope12 = response.carbon_footprint[0] ? parseFloat(response.carbon_footprint[0]) : 0;
      const emissionsScope3 = response.carbon_footprint[1] ? parseFloat(response.carbon_footprint[1]) : 0;
      const totalEmissionsKt = emissionsScope12 + emissionsScope3;

      if (totalEmissionsKt < 1) {
        formattedValue = `${(totalEmissionsKt * 1000).toFixed(0)} t`;
      } else if (totalEmissionsKt < 10) {
        formattedValue = `${totalEmissionsKt.toFixed(2)} kt`;
      } else if (totalEmissionsKt < 100) {
        formattedValue = `${totalEmissionsKt.toFixed(1)} kt`;
      } else if (totalEmissionsKt < 1000) {
        formattedValue = `${totalEmissionsKt.toFixed(0)} kt`;
      } else if (totalEmissionsKt < 10000) {
        formattedValue = `${(totalEmissionsKt / 1000).toFixed(2)} Mt`;
      } else {
        formattedValue = `${(totalEmissionsKt / 1000).toFixed(1)} Mt`;
      }

      setFormattedTotalCarbonFootprintValue(formattedValue);

      loadBootstrapTooltips();
    });
  }, [profile]);

  const blocInfo = maturityInfo.bilanCarbone;
  const levelInfo = blocInfo.levels[getCarbonFootprintLevel(carbonFootprint)];

  return (
    <div className="mb-4 tw-border tw-border-solid tw-border-greySix tw-rounded">
      <div className={`${profile.publishing?.actions.includes(getUserLocal()) ? 'tw-rounded-t' : 'tw-rounded'} p-4 white-bg d-flex flex-column pe-md-0`}>
        <h3 className="tw-text-greyOne tw-text-base align-middle d-flex justify-content-between align-items-center me-0 me-md-4 tw-flex">
          <span className="tw-flex">
            {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_3')}
          </span>
          <div className="tw-flex tw-gap-4">
            {((profile.type === 1 && !profile.is_managed) || (profile.type === 1 && fromParentCompany))
              ? (
                <div className="tw-text-greyFive tw-font-normal tw-text-sm tw-flex tw-items-center">
                  {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_2')} {profile.parent_company_name}
                </div>
              ) : null}
          </div>
        </h3>
        <div className="tw-p-4 tw-mr-6 tw-border tw-border-solid tw-border-greySix tw-bg-backgroundCleanBlue tw-mt-4 tw-mb-6 tw-rounded-sm tw-text-sm tw-text-primaryNewBlue tw-font-medium tw-flex tw-items-center tw-gap-2">
          <AdvancementCircle level={levelInfo.level} />
          {comment}
        </div>
        {(carbonFootprint && (carbonFootprint[0] || carbonFootprint[1])) || (isControledPmeWithoutCarbonFootprint) ? (
          <div className={`tw-text-sm tw-text-greyFive tw-me-0 tw-me-md-4 ${((profile.type === 1 && !profile.is_managed) || fromParentCompany) ? 'tw-mb-3' : 'tw-mb-6'}`}>
            {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_9')}
          </div>
        ) : null}
        <div className="xl:tw-grid tw-grid-cols-12 tw-mt-4">
          <div className="tw-col-span-4">
            <div className="carbon-footprint-chart-container tw-h-64 tw-overflow-visible tw-py-3">
              <CarbonFootprintDistributionPie
                totalCarbonFootprintValue={totalCarbonFootprintValue}
                formattedTotalCarbonFootprintValue={formattedTotalCarbonFootprintValue}
                carbonFootprintYear={carbonFootprintYear}
                data={chartDataFiltered}
                scope12={carbonFootprint && carbonFootprint[0]}
                scope3={carbonFootprint && carbonFootprint[1]}
              />
            </div>
          </div>
          {((carbonFootprint && (carbonFootprint[0] && carbonFootprint[1])) || (isControledPmeWithoutCarbonFootprint)) ? (
            <div className="tw-col-span-8 tw-my-auto xl:tw-grid tw-grid-cols-12 xl:tw-ps-5 tw-pe-0 tw-text-sm">
              <div className="tw-flex tw-mt-6 xl:tw-hidden tw-gap-1">
                <div className="xl:tw-ms-6 tw-self-center tw-text-[13px] tw-px-3 tw-py-1.5 tw-flex tw-gap-1 tw-flex-nowrap tw-bg-[#21AFFF1A] tw-text-[#21AFFF] tw-rounded-full tw-relative">
                  <div>
                    Scope 1&2
                  </div>
                  <div className="group">
                    <img src={circleQuestion} width="auto" height="auto" alt="circle question" className="tw-mt-0.5" />
                    <div className="sm:tw-w-auto tw-invisible group-hover:tw-visible tw-absolute md:tw-top-full tw-min-w-64 md:tw-left-[160px] tw-z-10 tw-p-2 tw-text-xs tw-text-white tw-font-normal tw-font-moskauGrotesk tw-bg-greyThree tw-rounded-sm tw-shadow-lg -tw-m-10">
                      {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_13')}
                    </div>
                  </div>
                </div>
                <div className="xl:tw-ms-6 tw-self-center tw-text-[13px] tw-px-3 tw-py-1.5 tw-flex tw-gap-1 tw-bg-[#000D850D] tw-text-[#000D85AD] tw-rounded-full tw-relative">
                  <div>
                    Scope 3
                  </div>
                  <div className="group">
                    <img src={circleQuestionScope3} width="auto" height="auto" alt="circle question" className="tw-mt-0.5" />
                    <div className="sm:tw-w-auto tw-invisible group-hover:tw-visible tw-absolute md:tw-top-full tw-min-w-64 tw-right-0 md:tw-left-[160px] tw-z-10 tw-p-2 tw-text-xs tw-text-white tw-font-normal tw-font-moskauGrotesk tw-bg-greyThree tw-rounded-sm tw-shadow-lg -tw-m-10">
                      {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_14')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tw-col-span-8 xl:tw-px-0 tw-flex tw-mt-6 xl:tw-mt-0">
                <div className="tw-self-center tw-h-5 tw-w-5 tw-min-h-5 tw-min-w-5 tw-rounded-full tw-text-center tw-text-white tw-text-base tw-font-medium tw-flex" style={{ backgroundColor: '#21AFFF' }} />
                <div className="tw-self-center tw-ms-3 tw-pt-1 tw-text-greyOne">
                  {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_10')}
                </div>
              </div>
              <div className="tw-col-span-4 xl:tw-flex tw-hidden tw-border-solid tw-border-r-0 tw-border-y-0 tw-border-l tw-border-greySix tw-relative">
                <div className="tw-ms-1 md:tw-ms-6 tw-self-center tw-text-[13px] tw-px-3 tw-py-1.5 tw-flex tw-flex-nowrap tw-bg-[#21AFFF1A] tw-rounded-full">
                  <div className="tw-text-[#21AFFF]">
                    Scope 1&2
                  </div>
                  <div className="group tw-self-center">
                    <img className="tw-ms-1 tw-mb-0.5" src={circleQuestion} width="auto" height="auto" alt="circle question" />
                    <div className="sm:tw-w-auto tw-invisible group-hover:tw-visible tw-absolute md:tw-top-full tw-min-w-64 md:tw-left-full tw-z-10 tw-p-2 tw-text-xs tw-text-white tw-font-normal tw-font-moskauGrotesk tw-bg-greyThree tw-rounded-sm tw-shadow-lg -tw-m-10">
                      {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_13')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tw-col-span-8 tw-pe-0 md:tw-ps-0">
                {(chartDataFiltered) ? (
                  chartDataFiltered.filter((item) => item.scope === 3).map((item, index) => (
                    <div key={index} className="tw-flex tw-mt-6">
                      <div className="tw-self-center tw-h-5 tw-w-5 tw-min-h-5 tw-min-w-5 tw-rounded-full tw-text-center tw-text-white tw-text-base tw-font-medium tw-flex" style={{ backgroundColor: item.color }} />
                      <div className="tw-self-center tw-ms-3 tw-pt-1 tw-text-greyOne">
                        {t(item.id)}
                      </div>
                    </div>
                  ))
                ) : (
                  ''
                )}
              </div>
              <div className="tw-col-span-4 xl:tw-flex tw-hidden tw-mt-6 tw-pe-0 tw-border-solid tw-border-r-0 tw-border-y-0 tw-border-l tw-border-greySix">
                <div className="tw-ms-1 md:tw-ms-6 tw-self-center tw-text-[13px] tw-px-3 tw-py-1.5 tw-flex tw-bg-[#000D850D] tw-text-[#000D85AD] tw-rounded-full tw-relative">
                  <div>
                    Scope 3
                  </div>
                  <div className="group tw-self-center">
                    <img className="tw-ms-1 tw-mb-0.5" width="auto" height="auto" src={circleQuestionScope3} alt="circle question scope 3" />
                    <div className="sm:tw-w-auto tw-invisible group-hover:tw-visible tw-absolute md:tw-top-full tw-min-w-64 md:tw-left-[140px] tw-z-10 tw-p-2 tw-text-xs tw-text-white tw-font-normal tw-font-moskauGrotesk tw-bg-greyThree tw-rounded-sm tw-shadow-lg -tw-m-10">
                      {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_14')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {(carbonFootprint && (!carbonFootprint[0] && !carbonFootprint[1]) && carbonFootprintDistribution?.length === 0) ? (
            <div className="xl:tw-col-span-8 tw-flex xl:tw-ps-5 tw-mt-4 xl:tw-mt-0 tw-items-center tw-mr-6">
              <div className="tw-w-full tw-flex tw-items-center tw-justify-center tw-bg-greyEight tw-py-6 tw-px-2 tw-rounded">
                <div className="tw-text-center tw-flex tw-items-center tw-justify-center tw-gap-3">
                  {isPmeWithCommitment ? (
                    <>
                      <div className={`${isPlannedPublicationDateValid ? 'tw-bg-greenLightTwo' : 'tw-bg-greyLightFive'} tw-p-3 tw-rounded-full`}>
                        <img
                          src={isPlannedPublicationDateValid ? calendarCheckBlue : calendarCrossBlue}
                          alt={isPlannedPublicationDateValid ? 'calendar check blue' : 'calendar cross blue'}
                          height={32}
                          width={32}
                        />
                      </div>
                      {isPmeWithCommitment && (
                        <div className="tw-flex tw-flex-col tw-gap-1 tw-text-left">
                          <span className="tw-text-sm tw-text-greyThree">{t('CARBON_FOORPRINT_DISTRIBUTION_EXPECTED_P_DATE')}</span>
                          <span className="tw-text-sm tw-font-semibold tw-text-greyOne">
                            {isPlannedPublicationDateValid ? (
                              t('CARBON_FOORPRINT_DISTRIBUTION_BEFORE').concat(plannedPublicationDate)
                            ) : (
                              plannedPublicationDate.charAt(0).toUpperCase() + plannedPublicationDate.slice(1)
                            )}
                          </span>
                          <span className="tw-text-xs tw-text-greyFour">
                            {t('CARBON_FOORPRINT_COMMITMENT').concat(commitmentDate)}
                          </span>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="tw-bg-greyLightFive tw-p-3 tw-rounded-full">
                        <img src={crossIconGrey} alt="cross icon grey" height={32} width={32} />
                      </div>
                      <div className="tw-text-sm tw-text-greyThree mt-2">
                        {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_16')}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : null }
          {(carbonFootprint && !isControledPmeWithoutCarbonFootprint && ((carbonFootprint[0] || carbonFootprint[1]) && !(carbonFootprint[0] && carbonFootprint[1]))) ? (
            <div className="tw-col-span-8 tw-my-auto xl:tw-grid tw-grid-cols-12 xl:tw-ps-5 tw-pe-0 tw-text-sm">
              <div className="tw-flex tw-mt-6 xl:tw-hidden tw-gap-1">
                <div className={`xl:tw-ms-6 tw-self-center tw-text-[13px] tw-px-3 tw-py-1.5 tw-flex tw-gap-1 tw-flex-nowrap tw-rounded-full ${carbonFootprint[0] ? 'tw-bg-[#21AFFF1A] tw-text-[#21AFFF]' : 'tw-bg-[#9E9E9E0D] tw-text-greyFive'}`}>
                  <div>
                    Scope 1&2
                  </div>
                  <img src={carbonFootprint[0] ? circleQuestion : circleQuestionGrey} width="auto" height="auto" alt="circle question" data-bs-toggle="tooltip" data-bs-placement="right" title={t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_13')} />
                </div>
                <div className={`xl:tw-ms-6 tw-self-center tw-text-[13px] tw-px-3 tw-py-1.5 tw-flex tw-gap-1 tw-rounded-full ${carbonFootprint[1] ? 'tw-bg-[#000D850D] tw-text-[#000D85AD]' : 'tw-bg-[#9E9E9E0D] tw-text-greyFive'}`}>
                  <div>
                    Scope 3
                  </div>
                  <img src={carbonFootprint[1] ? circleQuestionScope3 : circleQuestionGrey} width="auto" height="auto" alt="circle question scope 3" data-bs-toggle="tooltip" data-bs-placement="right" title={t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_14')} />
                </div>
              </div>
              <div className="tw-col-span-8 xl:tw-px-0 tw-flex tw-mt-6 xl:tw-mt-0">
                <div className="tw-self-center tw-h-5 tw-w-5 tw-min-h-5 tw-min-w-5 tw-rounded-full tw-text-center tw-text-white tw-text-base tw-font-medium tw-flex" style={{ backgroundColor: carbonFootprint[0] ? '#21AFFF' : '#F0F1F5' }} />
                <div className={`tw-self-center tw-ms-3 tw-pt-1 ${carbonFootprint[0] ? 'tw-text-greyOne' : 'tw-text-greyFive'}`}>
                  {carbonFootprint[0] ? t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_10') : t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_16')}
                </div>
              </div>
              <div className="tw-col-span-4 xl:tw-flex tw-hidden tw-border-solid tw-border-r-0 tw-border-y-0 tw-border-l tw-border-greySix tw-relative">
                <div className={`tw-ms-1 md:tw-ms-6 tw-self-center tw-text-[13px] tw-px-3 tw-py-1.5 tw-flex tw-flex-nowrap tw-rounded-full ${carbonFootprint[0] ? 'tw-bg-[#21AFFF1A] tw-text-[#21AFFF]' : 'tw-bg-[#9E9E9E0D] tw-text-greyFive'}`}>
                  <div>
                    Scope 1&2
                  </div>
                  <div className="group tw-self-center">
                    <img className="tw-ms-1 tw-mb-0.5" src={circleQuestion} width="auto" height="auto" alt="circle question" />
                    <div className="sm:tw-w-auto tw-invisible group-hover:tw-visible tw-absolute md:tw-top-full tw-min-w-64 md:tw-left-full tw-z-10 tw-p-2 tw-text-xs tw-text-white tw-font-normal tw-font-moskauGrotesk tw-bg-greyThree tw-rounded-sm tw-shadow-lg -tw-m-10">
                      {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_13')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tw-col-span-8 tw-pe-0 md:tw-ps-0">
                <div className="tw-flex tw-mt-6">
                  <div className="tw-self-center tw-h-5 tw-w-5 tw-min-h-5 tw-min-w-5 tw-rounded-full tw-text-center tw-text-white tw-text-base tw-font-medium tw-flex" style={{ backgroundColor: carbonFootprint[1] ? '#000D85' : '#F0F1F5' }} />
                  <div className={`tw-self-center tw-ms-3 tw-pt-1 ${carbonFootprint[1] ? 'tw-text-greyOne' : 'tw-text-greyFive'}`}>
                    {carbonFootprint[1] ? t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_12') : t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_16')}
                  </div>
                </div>
              </div>
              <div className="tw-col-span-4 xl:tw-flex tw-hidden tw-mt-6 tw-pe-0 tw-border-solid tw-border-r-0 tw-border-y-0 tw-border-l tw-border-greySix">
                <div className={`tw-ms-1 md:tw-ms-6 tw-self-center tw-text-[13px] tw-px-3 tw-py-1.5 tw-flex tw-rounded-full tw-relative ${carbonFootprint[1] ? 'tw-bg-[#000D850D] tw-text-[#000D85AD]' : 'tw-bg-[#9E9E9E0D] tw-text-greyFive'}`}>
                  <div>
                    Scope 3
                  </div>
                  <div className="group tw-self-center">
                    <img className="tw-ms-1 tw-mb-0.5" width="auto" height="auto" src={circleQuestionScope3} alt="circle question scope 3" />
                    <div className="sm:tw-w-auto tw-invisible group-hover:tw-visible tw-absolute md:tw-top-full tw-min-w-64 md:tw-left-[140px] tw-z-10 tw-p-2 tw-text-xs tw-text-white tw-font-normal tw-font-moskauGrotesk tw-bg-greyThree tw-rounded-sm tw-shadow-lg -tw-m-10">
                      {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_14')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null }
        </div>
        {carbonFootprintDistribution?.length > 0 ? (
          <>
            <button href="#" type="button" onClick={toggleTable} className="d-flex border-0 bg-white tw-text-sm mt-4 ps-0 tw-text-greyFour tw-underline">
              {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_15')}
            </button>
            <div style={{ maxHeight: (displayTable) ? '999px' : '0px', overflow: 'hidden' }} className="anim-all-1s me-0 me-md-4">
              {chartData.sort((a, b) => b.value - a.value).map((item, index) => (
                <div key={index} className="mt-3 row">
                  <div className="font-14 fw-400 col-md-6">
                    {t(item.id)}
                  </div>
                  <div className="d-flex col-md-6 mt-1 mt-md-0">
                    <div style={{ height: '100%', width: `${item.value}%`, backgroundColor: item.color, borderRadius: '6px' }} />
                    <div className="ms-2 font-14 fw-600">
                      {parseFloat(item.value).toFixed(1)}%
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default CarbonFootprintDistribution;
