import React, { useEffect, useState, useRef } from 'react';

import FilterDropdownButton from './FilterDropdownButton';
import FilterDropdownContent from './FilterDropdownContent';

function FilterDropdown({ levels, selectedCategoryId = null, onChange, navigationPath, setNavigationPath }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getCurrentLevel = () => {
    if (!levels || !Array.isArray(levels) || levels.length === 0) {
      return {
        currentLevel: null,
        currentCategories: [],
      };
    }

    // pas de navigation dans le menu, niveau le plus haut
    if (navigationPath.length === 0) {
      const highestLevel = levels[0]?.level?.[0];
      return {
        currentLevel: highestLevel,
        currentCategories: highestLevel?.categories || [],
      };
    }

    // dernière navigation dans le menu
    const lastNavigation = navigationPath[navigationPath.length - 1];
    // Cherche le niveau correspondant à la position suivante dans le menu
    const currentLevelData = levels.find(
      (l) => l.level?.[0]?.position === lastNavigation.nextPosition,
    );

    // si pas de niveau suivant, on cherche les sous categories
    if (!currentLevelData) {
      const topLevel = levels[0]?.level?.[0];
      const category = topLevel?.categories?.find(
        (cat) => cat.id === lastNavigation.categoryId,
      );

      return {
        currentLevel: topLevel,
        currentCategories: category?.sub_categories || [],
      };
    }

    // si on a un ID, on cherche la categ dans le niveau courant pour afficher les sous categ
    if (lastNavigation.categoryId) {
      const currentCategory = currentLevelData.level[0].categories?.find(
        (cat) => cat.id === lastNavigation.categoryId,
      );

      return {
        currentLevel: currentLevelData.level[0],
        currentCategories: currentCategory?.sub_categories || [],
      };
    }

    return {
      currentLevel: currentLevelData.level[0],
      currentCategories: currentLevelData.level[0].categories || [],
    };
  };

  // Selection d'une categ de niveau 0
  const handleSelect = (category) => {
    if (selectedCategoryId === category.id) {
      onChange(null);
    } else {
      onChange(category.id);
      setIsOpen(false);
    }
  };

  // Descendre dans le sous menu ou selectionner la catégorie si le niveau est 0
  const handleCategorySelect = (category) => {
    if (!category) return;

    const { currentLevel } = getCurrentLevel();
    if (!currentLevel) return;

    const nextPosition = currentLevel.position - 1;

    if (category.sub_categories?.length > 0) {
      setNavigationPath([
        ...navigationPath,
        {
          categoryId: category.id,
          categoryName: category.name,
          nextPosition: currentLevel.position,
        },
      ]);
      return;
    }

    const nextLevel = levels?.find(
      (l) => l.level?.[0]?.position === nextPosition,
    );
    const categoryInNextLevel = nextLevel?.level?.[0]?.categories?.find(
      (cat) => cat.id === category.id,
    );

    if (categoryInNextLevel?.sub_categories?.length > 0) {
      setNavigationPath([
        ...navigationPath,
        {
          categoryId: category.id,
          categoryName: category.name,
          nextPosition,
        },
      ]);
    } else {
      handleSelect(category);
    }
  };

  // Selection de "toute la catégorie"
  const handleSelectAll = () => {
    if (!navigationPath.length) return;

    const lastNavigation = navigationPath[navigationPath.length - 1];
    const parentCategoryId = lastNavigation.categoryId;

    if (selectedCategoryId === parentCategoryId) {
      onChange(null);
    } else {
      onChange(parentCategoryId);
      setIsOpen(false);
    }
  };

  // retour dans le menu
  const handleBack = () => {
    setNavigationPath(navigationPath.slice(0, -1));
  };

  const handleDropdownClose = () => {
    setIsOpen(false);
    setNavigationPath([]);
  };

  const handleToggle = () => {
    if (isOpen) {
      handleDropdownClose();
    } else {
      setIsOpen(true);
    }
  };

  const { currentLevel, currentCategories } = getCurrentLevel();

  return (
    <div ref={dropdownRef} className="tw-relative tw-rounded-sm tw-max-w-60">
      <FilterDropdownButton
        isOpen={isOpen}
        onToggle={handleToggle}
      />

      <FilterDropdownContent
        isOpen={isOpen}
        navigationPath={navigationPath}
        currentCategories={currentCategories}
        selectedCategoryId={selectedCategoryId}
        onBack={handleBack}
        onSelectAll={handleSelectAll}
        onCategorySelect={handleCategorySelect}
        currentLevel={currentLevel}
        levels={levels}
      />
    </div>
  );
}

export default FilterDropdown;
