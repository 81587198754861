import { useState, useEffect, useCallback } from 'react';
import { useFetch } from 'use-http';
import handleResponse from '../utils/handleResponse';

const useCategories = (partnerId) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const { get, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partnerId}/categories`, {
    cachePolicy: 'no-cache',
  });

  const getCategories = async () => {
    try {
      const data = await get();
      if (response.ok) {
        setCategories(data);
      }
    } catch (error) {
      handleResponse(error);
      throw new Error(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const getCategoryName = useCallback((categoryId) => {
    return categories?.flatMap((levelData) => levelData.level?.[0]?.categories || [])
      .reduce((name, category) => {
        if (category.id === categoryId) return category.name;
        const subCategory = (category.sub_categories || [])
          .find((sub) => sub.id === categoryId);
        if (subCategory) return subCategory.name;
        return name;
      }, '') || '';
  }, [categories]);

  const addCategory = useCallback((categoryId) => {
    setSelectedCategoryId(categoryId);
  }, []);

  const removeCategory = useCallback(() => {
    setSelectedCategoryId(null);
  }, []);

  return {
    categories,
    selectedCategoryId,
    setSelectedCategoryId,
    getCategoryName,
    addCategory,
    removeCategory,
  };
};

export default useCategories;
