import React from 'react';
import dropdownArrow from '../../../../assets/ui/caret-down-s.svg';
import checkWhite from '../../../../assets/ui/check-white.svg';

function CategoryButton({ category, currentLevel, levels, selectedCategoryId, onClick }) {
  const hasSubCategories = () => {
    if (category.sub_categories?.length > 0) {
      return true;
    }

    if (currentLevel?.position != null) {
      const nextLevel = levels?.find(
        (l) => l.level?.[0]?.position === currentLevel.position - 1,
      );
      return nextLevel?.level?.[0]?.categories?.find(
        (cat) => cat.id === category.id,
      )?.sub_categories?.length > 0;
    }

    return false;
  };

  const showArrow = hasSubCategories();

  return (
    <button
      type="button"
      className="tw-px-4 tw-py-3 tw-w-full tw-flex tw-justify-between tw-text-xs tw-text-left tw-border-b tw-border-t-0 tw-border-x-0 tw-border-solid tw-border-b-greySeven tw-text-greyTwo tw-bg-white hover:tw-bg-greyEight"
      onClick={onClick}
    >
      <div className="tw-w-44 tw-text-left tw-text-sm tw-text-primaryNewBlue tw-font-moskauGrotesk tw-font-medium">
        {category.name}
      </div>
      {showArrow ? (
        <img
          src={dropdownArrow}
          className="-tw-rotate-90"
          alt="Sub categories"
        />
      ) : (
        <div
          className={`tw-min-w-4 tw-min-h-4 tw-flex tw-items-center tw-justify-center tw-rounded-full ${
            selectedCategoryId === category.id
              ? 'tw-bg-primaryNewBlue'
              : 'tw-border tw-border-solid tw-border-greyFour'
          }`}
        >
          {selectedCategoryId === category.id && (
            <img src={checkWhite} alt="Check" width={10} />
          )}
        </div>
      )}
    </button>
  );
}

export default CategoryButton;
