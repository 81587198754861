/**
 * Formats the objectives percentage text
 *
 * @param {Function} t - The translation function
 * @param {number} percentage - The percentage to format
 * @param {number} reduceTypeName - The index to select the type name
 * @returns {string} The formatted percentage text
 */
const formatObjectivesPercentage = (t, percentage, reduceTypeName) => {
  const typeName = ['intensity', 'absolute', 'value', 'PER_UNIT_OF_VALUE'];

  return `${percentage}% ${t(typeName[reduceTypeName])}`;
};

export default formatObjectivesPercentage;
