import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import useFetch from 'use-http';
import getLocalLink from '../../../../utils/getLocalLink';
import checkPasswordStrenght from '../../../../utils/checkPasswordStrenght';
import handleResponse from '../../../../utils/handleResponse';

function ResetPasswordForm() {
  const [searchParams] = useSearchParams();
  const [submited, setSubmited] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation();

  const { post, response, loading } = useFetch(
    process.env.REACT_APP_PARTNER_API,
  );

  const onSubmit = async (formData) => {
    try {
      await post('/login/reset', {
        token: searchParams.get('token'),
        email: searchParams.get('email'),
        password: formData.password,
        confirmPassword: formData.confirmPassword,
      });
      if (response.ok) {
        setSubmited(true);
      }

      if (response.status === 401) {
        setTokenExpired(true);
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    }
  };

  return (
    <>
      <h1 className="tw-text-3xl tw-text-greyOne tw-text-center tw-mt-10">
        {t('RESET_PASSWORD.TITLE')}
      </h1>
      <div>
        {submited ? (
          <>
            <p className="tw-mt-4">{t('RESET_PASSWORD.SUBMITED')}</p>
            <div className="tw-mt-5">
              <a href={getLocalLink('/login')} className="tw-text-secondarySkyBlue">
                {t('RESET_PASSWORD.LOGIN_LINK')}
              </a>
            </div>
          </>
        ) : (
          <form
            id="partner-forgot-form"
            className="tw-mt-[30px]"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <label className="tw-mb-2.5 tw-font-medium" htmlFor="password">
              {t('RESET_PASSWORD.NEW_PASSWORD')}*
            </label>
            <input
              type="password"
              {...register('password', {
                required: t('PASSWORD_REQUIRED'),
                validate: (value) => {
                  if (!checkPasswordStrenght(value)) {
                    return t('RESET_PASSWORD.VALIDATION');
                  }
                  return true;
                },
              })}
              name="password"
              id="password"
              className="tw-flex tw-w-full tw-border-greySix tw-border tw-border-solid tw-rounded-md tw-py-3 tw-px-5 tw-text-greyFour focus:tw-border-secondarySkyBlue focus:tw-outline-secondarySkyBlue"
            />
            {errors.password && (
              <div className="invalid-feedback d-block">
                {errors.password.message}
              </div>
            )}
            <label
              className="tw-mb-2.5 tw-font-medium tw-mt-4"
              htmlFor="confirmPassword"
            >
              {t('RESET_PASSWORD.CONFIRM')}*
            </label>
            <input
              type="password"
              {...register('confirmPassword', {
                required: t('PASSWORD_REQUIRED'),
                validate: (value) => {
                  if (watch('password') !== value) {
                    return t('RESET_PASSWORD.NO_MATCH');
                  }
                  return true;
                },
              })}
              name="confirmPassword"
              id="confirmPassword"
              className="tw-flex tw-w-full tw-border-greySix tw-border tw-border-solid tw-rounded-md tw-py-3 tw-px-5 tw-text-greyFour focus:tw-border-secondarySkyBlue focus:tw-outline-secondarySkyBlue"
            />
            {errors.confirmPassword && (
              <div className="invalid-feedback d-block">
                {errors.confirmPassword.message}
              </div>
            )}
            {tokenExpired && <p className="tw-mt-1.5 tw-text-sm tw-text-redError">{t('RESET_PASSWORD.TOKEN_EXPIRED')}</p>}
            <button
              type="submit"
              className="tw-w-full tw-mt-[30px] tw-py-3 tw-bg-primaryNewBlue tw-border-0 tw-rounded tw-text-white"
              disabled={loading}
            >
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                t('RESET_PASSWORD.RESET')
              )}
            </button>
          </form>
        )}
      </div>
    </>
  );
}

export default ResetPasswordForm;
