import React, { useState, useEffect } from 'react';

import checkIcon from '../../../../assets/ui/check-icon.svg';

function AdvancementCircle({ level }) {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setAnimate(true), 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {(level === 3)
    && (
    <div className="tw-min-w-6 tw-h-6 tw-bg-primaryNewBlue tw-rounded-full tw-border tw-border-primaryNewBlue tw-flex items-center tw-justify-center">
      <img src={checkIcon} width={14} height={14} alt="check" className="tw-self-center" />
    </div>
    )}
      {(level === 2)
      && (
      <div className="tw-min-w-6 tw-h-6 tw-relative">
        <svg className="tw-w-full tw-h-full" viewBox="0 0 24 24">
          <circle
            cx="12"
            cy="12"
            r="11"
            fill="none"
            stroke="currentColor"
            className="text-primaryNewBlue"
            strokeWidth="2"
            strokeDasharray="69.12"
            strokeDashoffset={animate ? '17.28' : '69.12'}
            style={{
              transition: 'stroke-dashoffset 1s ease-out',
            }}
          />
        </svg>
      </div>
      )}
      {(level === 1)
      && <div className="tw-min-w-6 tw-h-6 tw-rounded-full tw-border-2 tw-border-greySix tw-flex tw-items-center tw-justify-center tw-border-solid" />}
    </>

  );
}

export default AdvancementCircle;
