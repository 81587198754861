import { useContext } from 'react';
import { SocketContext } from '../context/SocketContext';

export const useNotifications = (profileId) => {
  const { notifications, deleteNotification } = useContext(SocketContext);

  const hasUnreadNotifications = (categoryId = null, noteId = null) => {
    return notifications.some((notification) => {
      if (notification.profileId !== profileId) return false;
      if (noteId) return notification.noteId === noteId;
      if (categoryId) return notification.categoryId === categoryId;
      return true;
    });
  };

  const markNoteAsRead = (noteId) => {
    const notification = notifications.find(
      (n) => n.profileId === profileId && n.noteId === noteId,
    );

    if (notification) {
      deleteNotification(notification._id);
    }
  };

  return {
    hasUnreadNotifications,
    markNoteAsRead,
  };
};

export default useNotifications;
