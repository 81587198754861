import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import useFetch from 'use-http';
import handleResponse from '../../../../utils/handleResponse';

function ForgotPasswordForm() {
  const [submited, setSubmited] = useState(false);
  const [email, setEmail] = useState('');
  const { partner } = useOutletContext();
  const { post, response, loading } = useFetch(process.env.REACT_APP_PARTNER_API);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation();

  const onSubmit = async (formData) => {
    try {
      await post('/login/forgot', {
        domain: partner.domain,
        email: formData.email,
      });
      if (response.ok) {
        setSubmited(true);
        setEmail(formData.email);
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    }
  };

  return (
    <>
      <h1 className="tw-text-3xl tw-text-greyOne tw-text-center tw-mt-10">
        {t('LOGIN_FORM.FORGOTTEN_PASSWORD')}
      </h1>
      {submited ? `${t('PARTNER_LOGIN_TEXT_8')} ${email} ${t('PARTNER_LOGIN_TEXT_9')}` : (
        <form
          id="partner-forgot-form"
          className="tw-mt-[30px]"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <label className="tw-mb-2.5 tw-font-medium" htmlFor="inputEmail">
            Email *
          </label>
          <input
            type="email"
            {...register('email', {
              required: t('FORM_CONTACT_COMPANY_EMAIL_MISSING'),
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t('FORM_CONTACT_COMPANY_EMAIL_BAD_FORMAT'),
              },
            })}
            name="email"
            id="inputEmail"
            className="tw-flex tw-w-full tw-border-greySix tw-border tw-border-solid tw-rounded-md tw-py-3 tw-px-5 tw-text-greyFour focus:tw-border-secondarySkyBlue focus:tw-outline-secondarySkyBlue"
            autoComplete="email"
            placeholder={t('LOGIN_FORM.PASSWORD.PLACEHOLDER_EMAIL')}
          />
          {errors.email && (
            <div className="invalid-feedback d-block">{errors.email.message}</div>
          )}
          <button
            type="submit"
            className="tw-w-full tw-mt-[30px] tw-py-3 tw-bg-primaryNewBlue tw-border-0 tw-rounded tw-text-white"
            disabled={loading}
          >
            {loading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              t('PARTNER_LOGIN_TEXT_15')
            )}
          </button>
        </form>
      )}

    </>
  );
}

export default ForgotPasswordForm;
