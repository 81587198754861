import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AuthStrategies from '../../../authStrategies.json';
import Password from './AuthStrategies/Password';
import Mail from './AuthStrategies/Mail';

function Form() {
  const { partner } = useOutletContext();

  const { t } = useTranslation();

  const password = (partner.auth_strategy === AuthStrategies.password) && <Password />;
  const mail = (partner.auth_strategy === AuthStrategies.mail) && <Mail />;

  return (
    <>
      <h1 className="tw-text-3xl tw-text-greyOne tw-text-center tw-mt-10">
        {t('LOGIN_FORM.TEXT_A', { partnername: partner.name })}
      </h1>
      {mail}
      {password}
    </>
  );
}

export default Form;
