import React from 'react';
import cross from '../../../../assets/ui/cross-modal.svg';

function SelectedCategories({ categoryId, getCategoryName, onRemove, setNavigationPath }) {
  const handleCategoryRemove = (categoryId) => {
    onRemove(categoryId);
    setNavigationPath([]);
  };

  return (
    <div className="tw-flex tw-flex-wrap tw-gap-2 tw-mt-3">
      <button
        type="button"
        onClick={() => handleCategoryRemove(categoryId)}
        className="tw-bg-white tw-flex tw-items-center tw-gap-[14px] tw-px-4 tw-py-[9px] tw-border tw-border-solid tw-border-greySix tw-rounded-full tw-text-sm tw-text-primaryNewBlue tw-font-moskauGrotesk tw-font-medium"
      >
        <span className="-tw-mb-[3px]">{getCategoryName(categoryId)}</span>
        <img src={cross} width={20} alt="Delete" />
      </button>
    </div>
  );
}

export default SelectedCategories;
