import React, { createContext, useEffect, useState } from 'react';
import { initializeSocket, disconnectSocket } from '../socket';

export const SocketContext = createContext();

export function SocketProvider({ children, session }) {
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [notifications, setNotifications] = useState([]);

  // Initialisation du socket quand on a une session
  useEffect(() => {
    if (session?.id) {
      const socketInstance = initializeSocket(session);
      setSocket(socketInstance);
    } else {
      disconnectSocket();
      setSocket(null);
      setIsConnected(false);
      setNotifications([]);
    }

    return () => {
      disconnectSocket();
    };
  }, [session?.id]);

  // Configuration des événements socket seulement si on a un socket
  useEffect(() => {
    if (!socket) return undefined;

    const onConnect = () => {
      setIsConnected(true);
    };

    const onDisconnect = () => {
      setIsConnected(false);
    };

    const onNewNote = (newNotifications) => {
      setNotifications((prev) => (Array.isArray(newNotifications)
        ? [...prev, ...newNotifications]
        : [...prev, newNotifications]));
    };

    const onNotesList = (loadedNotifications) => {
      setNotifications(loadedNotifications);
    };

    const onNoteDeleted = ({ notificationId }) => {
      setNotifications((prev) => prev.filter((notification) => notification._id !== notificationId));
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('note:new', onNewNote);
    socket.on('notes:list', onNotesList);
    socket.on('note:deleted', onNoteDeleted);

    // Si déjà connecté, charger les notifications
    if (socket.connected && session?.id) {
      socket.emit('note:read', { userId: session.id });
    }

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('note:new', onNewNote);
      socket.off('notes:list', onNotesList);
      socket.off('note:deleted', onNoteDeleted);
    };
  }, [socket, session?.id]);

  const deleteNotification = (notificationId) => {
    if (socket && isConnected) {
      socket.emit('note:delete', { notificationId });
    }
  };
  return (
    <SocketContext.Provider
      value={{
        notifications,
        deleteNotification,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}
