import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import label from '../../../../assets/ui/label.svg';
import upSortArrowGrey from '../../../../assets/ui/database/arrows-down-up.svg';
import yourCompany from '../../../../assets/ui/your-company.png';

import SortArrow from '../SortArrow';
import toggleSort from '../../../../utils/toggleSort';
import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function NameHead({ filter, setFilter, sort, setSort, cellStyle }) {
  const { setLoading } = useContext(TableLoadingContext);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  // const [sort, setSort] = useState();

  const SORT_NAME = 'name';

  useEffect(() => {
    let initialSort = searchParams.get('sort');
    let initialFilter = searchParams.get('filter');

    if (!initialSort) {
      searchParams.set('sort', 'asc');
      initialSort = 'asc';
    }
    if (!initialFilter) {
      searchParams.set('filter', 'name');
      initialFilter = 'name';
    }

    setSearchParams(searchParams);
    setSort(initialSort);
    setFilter(initialFilter);
  }, [searchParams, setSearchParams, setSort, setFilter]);

  return (
    <th
      className="font-12 tw-px-3 tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySix tw-border tw-border-b-0"
      onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME, setLoading)}
      style={cellStyle}
    >
      <div className="tw-flex tw-justify-center">
        <span className="align-self-center">
          {t('SUPPLIER')}
          {filter === SORT_NAME ? (
            <SortArrow sort={sort} />
          ) : (
            <span className="text-end tw-ml-2.5 align-self-center">
              <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
            </span>
          )}
        </span>
      </div>
    </th>
  );
}

function NameBody({ profile, cellStyle, onClick }) {
  return (
    <td className="tw-h-[60px]" style={cellStyle}>
      <Link to={onClick(profile)} className="tw-px-3 tw-flex tw-items-center tw-h-full tw-no-underline tw-text-greyOne">
        <div className="data-img-row">
          <img src={`${profile?.logo_url ? process.env.REACT_APP_FILES_DOMAIN + profile.logo_url : yourCompany}`} className="img-fluid" alt="logo profile" loading="lazy" />
        </div>
        <div className="ps-3 table-name-text">
          <span className="me-1 tw-text-sm tw-text-greyOne tw-font-normal">{profile.name}</span>
          {profile.is_managed ? <img className="is-managed-data-icon" src={label} alt="label" /> : ''}
        </div>
      </Link>
    </td>
  );
}

export { NameHead, NameBody };
