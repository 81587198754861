import React from 'react';
import { useSearchParams } from 'react-router-dom';
import ForgotPasswordForm from './ForgotPasswordForm';
import ResetPasswordForm from './ResetPasswordForm';

function Reset() {
  const [searchParams] = useSearchParams();
  const hasTokenAndEmail = searchParams.has('token') && searchParams.has('email');

  return hasTokenAndEmail ? <ResetPasswordForm /> : <ForgotPasswordForm />;
}

export default Reset;
