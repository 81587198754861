import React from 'react';

import TableFeatures from '../../../tableFeatures.json';

import { GroupHead, GroupBody } from './Columns/Group';
import { CategoryHead, CategoryBody } from './Columns/Category';
import { SectorHead, SectorBody } from './Columns/Sector';
import { TurnoverHead, TurnoverBody } from './Columns/Turnover';
import { PartnerTurnoverHead, PartnerTurnoverBody } from './Columns/PartnerTurnover';
import { CarbonWeightHead, CarbonWeightBody } from './Columns/CarbonWeight';
import { StatusHead, StatusBody } from './Columns/Status';
import { CurrentProgressHead, CurrentProgressBody } from './Columns/CurrentProgress';
import { SbtTargetSimpleHead, SbtTargetSimpleBody } from './Columns/TargetSbtSimple';
import { SbtTargetHead, SbtTargetBody } from './Columns/SbtTarget';
import { ObjectifRythmeReduction12Head, ObjectifRythmeReduction12Body } from './Columns/ObjectifRythmeReduction12';
import { ObjectifRythmeReduction3Head, ObjectifRythmeReduction3Body } from './Columns/ObjectifRythmeReduction3';
import { RythmeReduction12RealHead, RythmeReduction12RealBody } from './Columns/RythmeReduction12Real';
import { RythmeReduction3RealHead, RythmeReduction3RealBody } from './Columns/RythmeReduction3Real';
import { RythmeReduction12Head, RythmeReduction12Body } from './Columns/RythmeReduction12';
import { RythmeReduction3Head, RythmeReduction3Body } from './Columns/RythmeReduction3';
import { CdpHead, CdpBody } from './Columns/Cdp';
import { RatingHead, RatingBody } from './Columns/Rating';
import { TagsHead, TagsBody } from './Columns/Tags';
import { ActionsCompletedHead, ActionsCompletedBody } from './Columns/ActionsCompleted';
import { ClimatActionHead, ClimatActionBody } from './Columns/ClimatAction';

const tableComponents = (filter, setFilter, sort, setSort, cellStyle, partner, currentProfile, categoryFilter, loading, profile, onClick) => [
  {
    id: TableFeatures.profileGroup,
    componentHead: <GroupHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />,
    componentBody: <GroupBody profile={currentProfile} cellStyle={cellStyle} onClick={onClick} />,
  },
  {
    id: TableFeatures.category,
    componentHead: <CategoryHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} categoryLevel0={partner.category_level?.find((element) => element.position === 0)} cellStyle={cellStyle} />,
    componentBody: <CategoryBody profile={currentProfile} cellStyle={cellStyle} onClick={onClick} />,
  },
  {
    id: TableFeatures.sector,
    componentHead: <SectorHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />,
    componentBody: <SectorBody profile={currentProfile} cellStyle={cellStyle} onClick={onClick} />,
  },
  {
    id: TableFeatures.turnover,
    componentHead: <TurnoverHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />,
    componentBody: <TurnoverBody profile={currentProfile} cellStyle={cellStyle} onClick={onClick} />,
  },
  {
    id: TableFeatures.profileTurnover,
    componentHead: <PartnerTurnoverHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} partner={partner} cellStyle={cellStyle} />,
    componentBody: <PartnerTurnoverBody profile={currentProfile} cellStyle={cellStyle} categoryFilter={categoryFilter} partnerId={partner.id} loading={loading} onClick={onClick} />,
  },
  {
    id: TableFeatures.profileCarbonWeight,
    componentHead: <CarbonWeightHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} partner={partner} cellStyle={cellStyle} />,
    componentBody: <CarbonWeightBody profile={currentProfile} cellStyle={cellStyle} categoryFilter={categoryFilter} partnerId={partner.id} loading={loading} onClick={onClick} />,
  },
  {
    id: TableFeatures.status,
    componentHead: <StatusHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />,
    componentBody: <StatusBody profile={currentProfile} cellStyle={cellStyle} onClick={onClick} />,
  },
  {
    id: TableFeatures.currentProgress,
    componentHead: <CurrentProgressHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />,
    componentBody: <CurrentProgressBody profile={currentProfile} cellStyle={cellStyle} onClick={onClick} />,
  },
  {
    id: TableFeatures.targetSbtSimple,
    componentHead: <SbtTargetSimpleHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />,
    componentBody: <SbtTargetSimpleBody profile={currentProfile} cellStyle={cellStyle} onClick={onClick} />,
  },
  {
    id: TableFeatures.targetSbt,
    componentHead: <SbtTargetHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />,
    componentBody: <SbtTargetBody profile={currentProfile} cellStyle={cellStyle} onClick={onClick} />,
  },
  {
    id: TableFeatures.objScope12,
    componentHead: <ObjectifRythmeReduction12Head filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />,
    componentBody: <ObjectifRythmeReduction12Body profile={currentProfile} cellStyle={cellStyle} onClick={onClick} />,
  },
  {
    id: TableFeatures.objScope3,
    componentHead: <ObjectifRythmeReduction3Head filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />,
    componentBody: <ObjectifRythmeReduction3Body profile={currentProfile} cellStyle={cellStyle} onClick={onClick} />,
  },
  {
    id: TableFeatures.scope12Real,
    componentHead: <RythmeReduction12RealHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />,
    componentBody: <RythmeReduction12RealBody profile={currentProfile} cellStyle={cellStyle} onClick={onClick} />,
  },
  {
    id: TableFeatures.scope3Real,
    componentHead: <RythmeReduction3RealHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />,
    componentBody: <RythmeReduction3RealBody profile={currentProfile} cellStyle={cellStyle} onClick={onClick} />,
  },
  {
    id: TableFeatures.scope12,
    componentHead: <RythmeReduction12Head filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />,
    componentBody: <RythmeReduction12Body profile={currentProfile} cellStyle={cellStyle} onClick={onClick} />,
  },
  {
    id: TableFeatures.scope3,
    componentHead: <RythmeReduction3Head filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />,
    componentBody: <RythmeReduction3Body profile={currentProfile} cellStyle={cellStyle} onClick={onClick} />,
  },
  {
    id: TableFeatures.scoreCdp,
    componentHead: <CdpHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />,
    componentBody: <CdpBody profile={currentProfile} cellStyle={cellStyle} onClick={onClick} />,
  },
  {
    id: TableFeatures.profileRating,
    componentHead: <RatingHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} partner={partner} cellStyle={cellStyle} />,
    componentBody: <RatingBody profile={currentProfile} partner={partner} cellStyle={cellStyle} onClick={onClick} />,
  },
  {
    id: TableFeatures.tags,
    componentHead: <TagsHead cellStyle={cellStyle} />,
    componentBody: <TagsBody profile={profile} cellStyle={cellStyle} onClick={onClick} />,
  },
  {
    id: TableFeatures.actionsCompleted,
    componentHead: <ActionsCompletedHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />,
    componentBody: <ActionsCompletedBody profile={currentProfile} cellStyle={cellStyle} onClick={onClick} />,
  },
  {
    id: TableFeatures.climateAction,
    componentHead: <ClimatActionHead filter={filter} setFilter={setFilter} sort={sort} setSort={setSort} cellStyle={cellStyle} />,
    componentBody: <ClimatActionBody profile={currentProfile} cellStyle={cellStyle} onClick={onClick} />,
  },
];

export default tableComponents;
