import React, { useEffect, useState, useRef } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { toast } from 'react-toastify';

import tailwindConfig from '../../../tailwind.config';
import getLocalLink from '../../../utils/getLocalLink';
import handleResponse from '../../../utils/handleResponse';

import { NameBody } from './Columns/Name';
import { ActionsBody } from './Columns/Actions';
import ToastMessage from '../../Dashboard/Category/ToastMessage';
import DeleteTagConfirmationModal from './Drawer/Tags/DeleteTagConfirmationModal';
import ProfileRating from './Drawer/ProfileRating/ProfileRating';
import Status from './Drawer/Status/Status';
import ProfileCategoryData from './Drawer/ProfileCategoryData/ProfileCategoryData';
import RenderTagSection from './Drawer/Tags/RenderTagSection';
import DrawerTemplate from './Drawer/DrawerTemplate';
import tableComponents from './tableComponents';

import Features from '../../../features.json';

function TableRow({ partner, partnerTags, getPartnerTags, category, profileStatusList, profile, tableComponent, hasWriteAccess, edit, isDashboard, cellStyle, categoryFilter, getProfiles, sectorFilter, sbtiFilter, sbtiFilterSimple, statusFilter, currentProgressFilter, actionPlanFilter, tagsFilter, sort, filter, tableFeatures }) {
  const [currentProfile, setCurrentProfile] = useState(profile);
  const [profileTags, setProfileTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const [profileEditStatus, setProfileEditStatus] = useState(false);
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const [isTagDropdownOpen, setIsTagDropdownOpen] = useState(false);
  const [isCreatingTag, setIsCreatingTag] = useState(false);
  const [shouldHardRefreshProfiles, setShouldHardRefreshProfiles] = useState(false);
  const [tonnageGlobal, setTonnageGlobal] = useState(0);
  const [turnoverGlobal, setTurnoverGlobal] = useState(0);
  const [hoveredTagId, setHoveredTagId] = useState(null);
  const [isEditingTag, setIsEditingTag] = useState(false);
  const [tagToEdit, setTagToEdit] = useState(null);
  const [openMenuTagId, setOpenMenuTagId] = useState(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [tagToDelete, setTagToDelete] = useState(null);
  const [tableComponentBodies, setTableComponentBodies] = useState([]);

  const tagDropdownRef = useRef(null);
  const tagTriggerRef = useRef(null);

  const { session } = useOutletContext();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const { features } = useOutletContext();

  // CONFIGURATION DU FORMULAIRE
  const defaultValues = {
    rating: currentProfile?.rating,
    carbon_weight: parseInt(currentProfile?.partner_carbon_weight, 10) / 1000,
    status: currentProfile?.status_id,
    tags: [],
    ...currentProfile?.categories?.reduce((acc, category) => {
      acc[`carbon_weight_${category.id}`] = category.carbon_weight / 1000 || '';
      return acc;
    }, {}),
    ...currentProfile?.categories?.reduce((acc, category) => {
      acc[`turnover_${category.id}`] = category.turnover_amount_categories / 1000000 || '';
      return acc;
    }, {}),
  };

  const { control, register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
    defaultValues,
  });

  // APPELS API
  const { put, post, del, loading, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const categoriesCarbonWeight = currentProfile?.categories?.map((category) => ({
    [`carbon_weight_${category.id}`]: category.value,
    [`turnover_${category.id}`]: category.value,
  }));

  // USEEFFECT
  useEffect(() => {
    const profileObj = edit?.find((obj) => obj.id === currentProfile.id);
    if (profileObj) {
      setProfileEditStatus(profileObj.edit);
    }
  }, [edit]);

  useEffect(() => {
    watch((value) => {
      const totalCarbonWeight = Object.keys(value)
        .filter((key) => key.startsWith('carbon_weight_'))
        .reduce((sum, key) => sum + (parseFloat(value[key]) || 0), 0);

      const totalTurnover = Object.keys(value)
        .filter((key) => key.startsWith('turnover_'))
        .reduce((sum, key) => sum + (parseFloat(value[key]) || 0), 0);

      setTonnageGlobal(totalCarbonWeight);
      setTurnoverGlobal(totalTurnover);
    });
  }, [watch]);

  useEffect(() => {
    if (partnerTags && partnerTags.length > 0) {
      setAvailableTags(partnerTags);
    }
  }, [partnerTags]);

  useEffect(() => {
    if (profile && profile.tags) {
      setProfileTags(profile.tags);
    }
  }, [profile]);

  useEffect(() => {
    if (profileTags.length > 0) {
      setSelectedTags(profileTags);
      setAvailableTags(partnerTags && partnerTags.filter((tag) => !profileTags.some((profileTag) => profileTag.id === tag.id)));
      setValue('tags', profileTags.map((tag) => tag.id));
    }
  }, [profileTags, partnerTags]);

  useEffect(() => {
    if (drawerVisibility) {
      setSelectedTags(profileTags);
      setAvailableTags(partnerTags && partnerTags.filter((tag) => !profileTags.some((profileTag) => profileTag.id === tag.id)));
      setValue('tags', profileTags.map((tag) => tag.id));
    }
  }, [drawerVisibility, profileTags, partnerTags]);

  // GESTION DE FORMULAIRE
  const onSubmit = async (data) => {
    let ratingResponse;

    if (defaultValues.rating !== data.rating) {
      const body = {
        rating: data.rating,
        partner_id: partner.id,
        currentProfile_id: currentProfile.id,
      };

      if (currentProfile.rating_id !== null && currentProfile.rating_id !== '' && currentProfile.rating_id !== undefined) {
        ratingResponse = await put(`/partner/${partner.id}/profiles/${currentProfile.id}/rating/${currentProfile.rating_id}`, body);
      } else {
        ratingResponse = await post(`/partner/${partner.id}/profiles/${currentProfile.id}/rating`, body);
      }

      handleResponse(response);
    }

    if (defaultValues.carbon_weight !== data.carbon_weight) {
      const body = {
        carbon_weight: data.carbon_weight,
        partner_id: partner.id,
        currentProfile_id: currentProfile.id,
      };

      if (currentProfile.partner_carbon_weight_id !== null) {
        await put(`/partner/${partner.id}/profiles/${currentProfile.id}/carbon-weight/${currentProfile.partner_carbon_weight_id}`, body);
      } else {
        await post(`/partner/${partner.id}/profiles/${currentProfile.id}/carbon-weight`, body);
      }

      handleResponse(response);
    }

    if (defaultValues.status !== data.status) {
      const body = {
        status_id: data.status,
        partner_id: partner.id,
        currentProfile_id: currentProfile.id,
      };

      if (currentProfile.current_status_id !== null) {
        await put(`/partner/${partner.id}/profiles/${currentProfile.id}/status/${currentProfile.current_status_id}`, body);
      } else {
        await post(`/partner/${partner.id}/profiles/${currentProfile.id}/status`, body);
      }

      handleResponse(response);
    }

    const status = profileStatusList.filter((profile) => profile.id === data.status);

    if (categoriesCarbonWeight) {
      await Promise.all(categoriesCarbonWeight.map(async (category) => {
        const defaultValueCarbonWeight = defaultValues[Object.keys(category)[0]];
        const dataValueCarbonWeight = data[Object.keys(category)[0]];
        const defaultValueTurnover = defaultValues[Object.keys(category)[1]];
        const dataValueTurnover = data[Object.keys(category)[1]];

        if (dataValueCarbonWeight !== defaultValueCarbonWeight || dataValueTurnover !== defaultValueTurnover) {
          const body = {
            user_id: session.id,
            carbon_weight: dataValueCarbonWeight,
            turnover: dataValueTurnover,
          };

          try {
            await post(`/partner/${partner.id}/profiles/${currentProfile.id}/carbon-weight-category/${Object.keys(category)[0].split('_')[2]}`, body);
          } catch (error) {
            handleResponse(response);
            throw new Error(error);
          }
        }
      }));
    }

    setCurrentProfile({ ...currentProfile,
      status_id: data.status,
      status_name: status[0]?.name,
      status_color: status[0]?.color,
      partner_carbon_weight: (parseInt(data.carbon_weight, 10) * 1000),
      rating: data.rating,
      rating_id: ratingResponse,
      categories: currentProfile.categories?.map((category) => ({
        ...category,
        carbon_weight: data[`carbon_weight_${category.id}`] * 1000,
      })),
    });

    toast(<ToastMessage text={<>{t('SUPPLIER_EDITED')} <span className=" tw-font-moskauGrotesk tw-font-semibold">{data.name}</span></>} />, {
      style: { backgroundColor: tailwindConfig.theme.colors.secondaryCreamBg },
    });

    setDrawerVisibility(false);
    setIsTagDropdownOpen(false);

    if (shouldHardRefreshProfiles) {
      await getProfiles(sort, filter, sectorFilter, categoryFilter, sbtiFilter, sbtiFilterSimple, statusFilter, currentProgressFilter, actionPlanFilter, tagsFilter, searchParams.get('p') || 0);
    }

    setShouldHardRefreshProfiles(false);
  };

  // FONCTIONS DE GESTION DES TAGS
  const handleTagAddition = async (tag) => {
    try {
      const body = {
        partner_id: partner.id,
        partner_tags_id: [tag.id],
      };

      await post(`/profiles/${currentProfile.id}/tags`, body);

      if (response.ok) {
        setSelectedTags((prevTags) => [...prevTags, tag]);
        setAvailableTags((prevTags) => prevTags.filter((t) => t.id !== tag.id));
        setValue('tags', [...selectedTags, tag].map((t) => t.id));
        setProfileTags((prevTags) => [...prevTags, tag]);
        setShouldHardRefreshProfiles(true);
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    }
  };

  const handleTagSelection = (tag) => {
    handleTagAddition(tag);
  };

  const handleTagRemoval = async (tag) => {
    try {
      const partnerTagsIds = JSON.stringify([tag.id]);
      await del(`/profiles/${currentProfile.id}/tags?partner_id=${partner.id}&partner_tags_id=${encodeURIComponent(partnerTagsIds)}`);

      if (response.ok) {
        setSelectedTags((prevTags) => prevTags.filter((t) => t.id !== tag.id));
        setAvailableTags((prevTags) => [...prevTags, tag]);
        setValue('tags', selectedTags.filter((t) => t.id !== tag.id).map((t) => t.id));
        setProfileTags((prevTags) => prevTags.filter((t) => t.id !== tag.id));
        setShouldHardRefreshProfiles(true);
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    }
  };

  const toggleTagDropdown = () => setIsTagDropdownOpen(!isTagDropdownOpen);

  const handleTagKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      toggleTagDropdown();
    }
  };

  const handleCreateTag = () => {
    setIsCreatingTag(true);
  };

  const handleCancelCreateTag = () => {
    setIsCreatingTag(false);
  };

  const handleTagCreated = async (createdTag) => {
    setSelectedTags((prevTags) => {
      if (prevTags.some((tag) => tag.id === createdTag.id)) {
        return prevTags;
      }
      const newTags = [...prevTags, createdTag];
      return newTags;
    });

    setAvailableTags((prevTags) => prevTags.filter((tag) => tag.id !== createdTag.id));

    setValue('tags', (oldValue) => {
      const currentTags = Array.isArray(oldValue) ? oldValue : [];
      return [...new Set([...currentTags, createdTag.id])];
    });

    setIsCreatingTag(false);

    await handleTagAddition(createdTag);
    await getPartnerTags();
  };

  const handleEditTag = (tag) => {
    setTagToEdit(tag);
    setIsEditingTag(true);
    setIsTagDropdownOpen(false);
    setOpenMenuTagId(null);
  };

  const handleCancelEditTag = () => {
    setIsEditingTag(false);
    setTagToEdit(null);
  };

  const handleTagEdited = (editedTag) => {
    setAvailableTags(availableTags.map((tag) => (tag.id === editedTag.id ? editedTag : tag)));
    setIsEditingTag(false);
    setTagToEdit(null);
  };

  const handleDeleteTag = (tag) => {
    setTagToDelete(tag);
    setIsDeleteConfirmationOpen(true);
    setOpenMenuTagId(null);
  };

  const confirmDeleteTag = async () => {
    try {
      await del(`/partner/${partner.id}/tags/${tagToDelete.id}`);

      if (response.ok) {
        getPartnerTags();
        setAvailableTags(availableTags.filter((tag) => tag.id !== tagToDelete.id));
        setSelectedTags(selectedTags.filter((tag) => tag.id !== tagToDelete.id));
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    } finally {
      setIsDeleteConfirmationOpen(false);
      setTagToDelete(null);
    }
  };

  const handleToggleMenu = (tagId) => {
    setOpenMenuTagId(openMenuTagId === tagId ? null : tagId);
  };

  const handleClickOutside = (event) => {
    if (tagDropdownRef.current && !tagDropdownRef.current.contains(event.target)) {
      setOpenMenuTagId(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // NAVIGATION
  const onClick = (currentProfile) => {
    if (profileEditStatus === false) {
      const basePath = isDashboard ? '/dashboard/suppliers' : '/profile';
      const newLink = getLocalLink(`${basePath}/${currentProfile.slug}`);
      const searchParam = category ? `${searchParams.get('partner') ? '&' : '?'}cf=${category}` : '';
      return `${newLink}${searchParam}`;
    }
    return '#';
  };

  const handleCloseDrawer = () => {
    setDrawerVisibility(false);
    setIsTagDropdownOpen(false);
    setIsCreatingTag(false);
  };

  const tableCompnentBodies = tableComponents(filter, null, sort, null, cellStyle, partner, currentProfile, categoryFilter, loading, profile);

  useEffect(() => {
    if (features.length > 0) {
      const tableComponent = [];
      tableFeatures?.forEach((feature) => {
        const component = tableCompnentBodies.find((component) => component.id === feature);
        if (component) {
          tableComponent.push(component.componentBody);
        }
      });

      setTableComponentBodies(tableComponent);
    }
  }, [tableFeatures]);

  // RENDU PRINCIPAL
  return (
    <tr className={`tw-border-solid tw-border-greySix tw-border ${profileEditStatus ? 'table-row-no-hover' : 'table-row'} align-middle`}>
      <NameBody profile={currentProfile} cellStyle={cellStyle} onClick={onClick} />
      {tableComponentBodies && tableComponentBodies.map((component, index) => (
        <React.Fragment key={index}>
          {React.cloneElement(component, { cellStyle, onClick })}
        </React.Fragment>
      ))}
      {(hasWriteAccess && (tableComponent?.status || tableComponent?.profileRating)) && <ActionsBody cellStyle={cellStyle} setDrawerVisibility={setDrawerVisibility} />}
      <DrawerTemplate
        title={t('DRAWER_SUPPLIER_TITLE')}
        drawerVisibility={drawerVisibility}
        setDrawerVisibility={handleCloseDrawer}
        setIsTagDropdownOpen={setIsTagDropdownOpen}
        className={drawerVisibility ? 'tw-cursor-default' : 'tw-hidden'}
        profile={currentProfile}
        partner={partner}
        tableComponent={tableComponent}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
      >
        <div className="tw-bg-backgroundCleanBlue border-greySix border tw-py-3 tw-px-7 tw-rounded-sm d-flex align-items-center">
          <img src={`${process.env.REACT_APP_FILES_DOMAIN}${currentProfile.logo_url}`} alt={currentProfile.name} className="border-greySix border tw-rounded-full tw-w-12 tw-h-12 tw-mr-5" />
          {currentProfile.name}
        </div>
        {tableComponent?.profileRating && (
          <ProfileRating
            partner={partner}
            register={register}
            errors={errors}
          />
        )}
        {tableComponent?.status && (
          <Status
            register={register}
            errors={errors}
            profileStatusList={profileStatusList}
          />
        )}
        {(tableComponent?.profileTurnover || tableComponent?.profileCarbonWeight || features.includes(Features.profilePartnerTurnover) || features.includes(Features.profilePartnerCarbonWeight)) && (
          <ProfileCategoryData
            register={register}
            errors={errors}
            tonnageGlobal={tonnageGlobal}
            turnoverGlobal={turnoverGlobal}
            currentProfile={currentProfile}
            partnerName={partner.name}
            hasTurnoverEditAccess={features.includes(Features.profilePartnerTurnover) || tableComponent?.profileTurnover}
            hasCarbonWeightEditAccess={features.includes(Features.profilePartnerCarbonWeight) || tableComponent?.profileCarbonWeight}
          />
        )}
        {/* BLOC ETIQUETTE */}
        {(tableComponent?.tags || features.includes(Features.profileTags)) && (
          <RenderTagSection
            partner={partner}
            handleCancelCreateTag={handleCancelCreateTag}
            handleTagCreated={handleTagCreated}
            handleCancelEditTag={handleCancelEditTag}
            handleTagEdited={handleTagEdited}
            partnerTags={partnerTags}
            getPartnerTags={getPartnerTags}
            tagToEdit={tagToEdit}
            control={control}
            selectedTags={selectedTags}
            handleTagRemoval={handleTagRemoval}
            isTagDropdownOpen={isTagDropdownOpen}
            toggleTagDropdown={toggleTagDropdown}
            tagDropdownRef={tagDropdownRef}
            tagTriggerRef={tagTriggerRef}
            handleTagKeyDown={handleTagKeyDown}
            isCreatingTag={isCreatingTag}
            isEditingTag={isEditingTag}
            tailwindConfig={tailwindConfig}
            availableTags={availableTags}
            setHoveredTagId={setHoveredTagId}
            hoveredTagId={hoveredTagId}
            handleTagSelection={handleTagSelection}
            handleCreateTag={handleCreateTag}
            handleToggleMenu={handleToggleMenu}
            openMenuTagId={openMenuTagId}
            handleEditTag={handleEditTag}
            handleDeleteTag={handleDeleteTag}
          />
        )}
        <DeleteTagConfirmationModal
          isOpen={isDeleteConfirmationOpen}
          onClose={() => setIsDeleteConfirmationOpen(false)}
          onConfirm={confirmDeleteTag}
          title={t('DELETE_TAG_CONFIRMATION_TITLE')}
          message={t('DELETE_TAG_CONFIRMATION_MESSAGE', { tagName: tagToDelete?.name })}
        />
      </DrawerTemplate>
    </tr>
  );
}

export default TableRow;
