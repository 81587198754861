import { io } from 'socket.io-client';

let socket = null;

export const initializeSocket = (session) => {
  if (socket) {
    return socket;
  }

  if (!session?.id) {
    return socket;
  }

  try {
    socket = io(process.env.REACT_APP_SOCKET_DOMAIN, {
      auth: {
        type: 'web',
      },
      withCredentials: true,
      reconnection: true,
      reconnectionDelay: 2000,
      reconnectionDelayMax: 5000,
    });

    socket.on('error', (error) => {
      throw new Error(error?.message);
    });
  } catch (error) {
    socket = null;
    throw error;
  }

  return socket;
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};
