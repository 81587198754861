import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../../../../../hooks/useNotifications';

import NotificationPoint from '../NotificationPoint';

import caretRight from '../../../../../assets/ui/caret-right.svg';
import dotsThree from '../../../../../assets/ui/dots-three.svg';

function Note({ profileId, setShowCategoryNotes, setNotes, selectedCategory, notes, handleClickShowSelectedNote, loading, session, del, response, getNotes, setEditedNote, setShowForm, categories }) {
  const [visibleOptions, setVisibleOptions] = useState(null);
  const { hasUnreadNotifications, markNoteAsRead } = useNotifications(profileId);

  const optionsRef = useRef(null);

  const { t } = useTranslation();

  const handleToggleOptions = (e, id) => {
    e.stopPropagation();
    setVisibleOptions(visibleOptions === id ? null : id);
  };

  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setVisibleOptions(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDeleteNote = async (e, id, categoryId) => {
    e.stopPropagation();
    await del(`/categories/${categoryId}/notes/${id}`);
    if (response.ok) {
      getNotes(selectedCategory.id);
    }
  };

  const handleEditNote = async (e, note) => {
    e.stopPropagation();
    setEditedNote(note);
    setShowForm(true);
  };

  const handleClickBackButton = () => {
    setShowCategoryNotes(false);
    setNotes([]);
  };

  const handleNoteClick = (note) => {
    markNoteAsRead(note.id);

    handleClickShowSelectedNote(note);
  };

  return (
    <>
      <button
        type="button"
        disabled={categories.length <= 1}
        className={`tw-text-greyFour tw-text-sm tw-flex tw-gap-1 tw-items-center tw-bg-white tw-border-0 tw-text-left tw-px-0 ${categories.length > 1 ? 'tw-cursor-pointer hover:tw-text-greyThree' : 'tw-cursor-default'}`}
        onClick={() => (categories.length > 1 ? handleClickBackButton() : null)}
      >
        {categories.length > 1 && (
          <img src={caretRight} alt="Caret right" />
        )}
        <span className="tw-pt-0.5">
          {selectedCategory?.category_name}
        </span>
      </button>
      {loading && (
        <div className="tw-bg-[#FDFDFD] tw-border tw-border-solid tw-border-greySeven tw-p-[14px] tw-rounded tw-mt-6">
          <div className="tw-bg-greySeven tw-animate-pulse tw-h-12 tw-rounded" />
        </div>
      )}
      {notes && !loading && notes?.map((note) => (
        <button
          type="button"
          key={note.id}
          className={`tw-relative tw-flex tw-justify-between tw-items-center tw-gap-2.5 tw-mt-2.5 tw-border tw-border-solid tw-border-greySeven tw-bg-[#FDFDFD] ${note.user_id === session.id ? 'tw-py-6' : 'tw-py-[16px]'} tw-px-[13px] tw-rounded tw-w-full tw-text-left hover:tw-border-secondarySkyBlue tw-relative`}
          onClick={() => handleNoteClick(note)}
        >
          {note.user_id === session.id && (
            <button
              type="button"
              className="tw-absolute tw-top-1.5 tw-right-1.5 tw-border-0 tw-bg-[#FDFDFD]"
              onClick={(e) => handleToggleOptions(e, note.id)}
            >
              <img src={dotsThree} alt="Dots three" />
            </button>
          )}
          {visibleOptions === note.id && (
            <div
              ref={optionsRef}
              className="tw-z-10 tw-absolute tw-top-8 tw-right-0 tw-p-1 tw-bg-white tw-rounded-[4px] tw-flex tw-flex-col tw-gap-1"
              style={{ boxShadow: '0px 5px 10px -3.89px rgba(0, 0, 0, 0.1)' }}
            >
              <button
                type="button"
                className="tw-mb-0 tw-text-greyThree tw-text-sm tw-border-0 tw-bg-white tw-py-0.5 tw-px-1.5 tw-text-left hover:tw-bg-greyEight tw-rounded-sm"
                onClick={(e) => handleEditNote(e, note)}
              >
                {t('NOTES.EDIT')}
              </button>
              <button
                type="button"
                className="tw-mb-0 tw-text-greyThree tw-text-sm tw-border-0 tw-bg-white tw-py-0.5 tw-px-1.5 tw-text-left hover:tw-bg-greyEight tw-rounded-sm"
                onClick={(e) => handleDeleteNote(e, note.id, note.partner_category_as_profile_id)}
              >
                {t('NOTES.DELETE')}
              </button>
            </div>
          )}
          <div>
            <div className="tw-text-greyThree tw-text-xs">
              {note.firstname} {note.lastname}
            </div>
            <div className="tw-text-primaryNewBlue tw-text-sm tw-font-semibold tw-mt-2.5">
              {note.title}
            </div>
          </div>
          <div className="tw-text-greyFour tw-text-xs">
            {note ? new Date(note.created_at).toLocaleDateString() : null}
          </div>
          {hasUnreadNotifications(null, note.id) && (
            <div className="tw-absolute tw-right-[6px] tw-top-[6px]">
              <NotificationPoint />
            </div>
          )}
        </button>
      ))}
    </>
  );
}

export default Note;
