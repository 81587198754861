import React from 'react';
import { useTranslation } from 'react-i18next';

import logoLeclerc from '../../../assets/ui/landingPartner/logo-leclerc.svg';
import logoAuchanRetail from '../../../assets/ui/landingPartner/logo-auchan-retail.svg';
import logoCasino from '../../../assets/ui/landingPartner/logo-casino.svg';
import logoLoreal from '../../../assets/ui/landingPartner/logo-loreal.svg';
import logoKellanova from '../../../assets/ui/landingPartner/logo-kellanova.svg';
import logoMcCormick from '../../../assets/ui/landingPartner/logo-mc-cormick.svg';
import logoNutritionSante from '../../../assets/ui/landingPartner/logo-nutrition-sante.svg';
import logoHenkel from '../../../assets/ui/landingPartner/logo-henkel.svg';
import logoBel from '../../../assets/ui/landingPartner/logo-bel.svg';
import logoJacquet from '../../../assets/ui/landingPartner/logo-jacquet.svg';
import logoMixBuffet from '../../../assets/ui/landingPartner/logo-mix-buffet.svg';
import logoBeiersdof from '../../../assets/ui/landingPartner/logo-beiersdof.svg';
import logoBarilla from '../../../assets/ui/landingPartner/logo-barilla.svg';
import logoLabeyrie from '../../../assets/ui/landingPartner/logo-labeyrie.svg';
import logoSodebo from '../../../assets/ui/landingPartner/logo-sodebo.svg';
import check from '../../../assets/ui/landingPartner/check.svg';

function About() {
  const { t } = useTranslation();

  return (
    <section className="tw-bg-white">
      <div className="tw-container tw-mx-auto tw-py-20 2xl:tw-p-20 tw-flex tw-gap-20 tw-flex-col 2xl:tw-flex-row">
        <div className="tw-w-full tw-flex tw-flex-col 2xl:tw-flex-row tw-order-2 2xl:tw-order-none tw-px-2.5 2xl:tw-px-0">
          <div className="tw-w-full tw-p-[30px] 2xl:tw-px-4 2xl:tw-py-[90px] tw-text-center tw-bg-[#FBFBFB]">
            <div className="tw-text-greyFour tw-text-xs">
              {t('LANDING_LECLERC.ABOUT.DISTRIBUTORS')}
            </div>
            <div className="tw-flex tw-items-center 2xl:tw-gap-9 2xl:tw-block tw-mt-6 2xl:tw-mt-0 tw-justify-between">
              <div className="2xl:tw-mt-[52px]">
                <img src={logoLeclerc} alt="Logo Leclerc" className="tw-w-3/4 sm:tw-w-max" />
              </div>
              <div className="2xl:tw-mt-[58px]">
                <img src={logoAuchanRetail} alt="Logo Auchan Retail" className="tw-w-3/4 sm:tw-w-max" />
              </div>
              <div className="2xl:tw-mt-[78px]">
                <img src={logoCasino} alt="Logo Casino" className="tw-w-3/4 sm:tw-w-max" />
              </div>
            </div>
          </div>
          <div className="tw-w-full tw-mt-[30px] 2xl:tw-mt-0">
            <div className="tw-w-full 2xl:tw-mt-[90px] tw-px-9">
              <div className="tw-text-greyFour tw-text-xs tw-text-center 2xl:tw-text-left">
                {t('LANDING_LECLERC.ABOUT.SUPPLIERS')}
              </div>
            </div>
            <div className="tw-flex tw-w-full tw-mt-9">
              <div className="tw-w-full tw-flex tw-flex-col tw-px-9">
                <div className="tw-flex tw-justify-center tw-mt-2">
                  <img src={logoLoreal} alt="Logo Loréal" className="tw-w-3/4 sm:tw-w-max" />
                </div>
                <div className="tw-flex tw-justify-center tw-mt-[60px]">
                  <img src={logoKellanova} alt="Logo Kellanova" className="tw-w-3/4 sm:tw-w-max" />
                </div>
                <div className="tw-flex tw-justify-center tw-mt-[60px]">
                  <img src={logoMcCormick} alt="Logo McCormick" className="tw-w-3/4 sm:tw-w-max" />
                </div>
                <div className="tw-flex tw-justify-center tw-mt-16">
                  <img src={logoNutritionSante} alt="Logo Nutrition & Santé" className="tw-w-3/4 sm:tw-w-max" />
                </div>
              </div>
              <div className="tw-w-full tw-flex tw-flex-col tw-px-9">
                <div className="tw-flex tw-justify-center">
                  <img src={logoHenkel} alt="Logo Henkel" className="tw-w-3/4 sm:tw-w-max" />
                </div>
                <div className="tw-flex tw-justify-center tw-mt-[50px]">
                  <img src={logoBel} alt="Logo Bel" className="tw-w-3/4 sm:tw-w-max" />
                </div>
                <div className="tw-flex tw-justify-center tw-mt-[50px]">
                  <img src={logoJacquet} alt="Logo Jacquet Brossard" className="tw-w-3/4 sm:tw-w-max" />
                </div>
                <div className="tw-flex tw-justify-center tw-mt-[50px]">
                  <img src={logoMixBuffet} alt="Logo Mix Buffet" className="tw-w-3/4 sm:tw-w-max" />
                </div>
              </div>
              <div className="tw-w-full tw-flex tw-flex-col tw-px-9">
                <div className="tw-flex tw-justify-center tw-mt-1">
                  <img src={logoBeiersdof} alt="Logo Beiersdof" className="tw-w-3/4 sm:tw-w-max" />
                </div>
                <div className="tw-flex tw-justify-center tw-mt-[65px]">
                  <img src={logoBarilla} alt="Logo Barilla" className="tw-w-3/4 sm:tw-w-max" />
                </div>
                <div className="tw-flex tw-justify-center tw-mt-[65px]">
                  <img src={logoLabeyrie} alt="Logo Labeyrie Fine Foods" className="tw-w-3/4 sm:tw-w-max" />
                </div>
                <div className="tw-flex tw-justify-center tw-mt-[65px]">
                  <img src={logoSodebo} alt="Logo Sodebo" className="tw-w-3/4 sm:tw-w-max" />
                </div>
              </div>
            </div>
            <div className="tw-text-greyThree tw-text-[10px] tw-mt-7 tw-text-center">
              {t('LANDING_LECLERC.ABOUT.TEXT_H')}
            </div>
          </div>
        </div>
        <div className="tw-w-full tw-text-primaryNewBlue tw-px-[30px] 2xl:tw-px-0">
          <h5 className="tw-text-3xl tw-font-bold tw-text-leclercOrange tw-text-center 2xl:text-left">
            {t('LANDING_LECLERC.ABOUT.TITLE')}
          </h5>
          <div className="tw-mt-10 tw-text-2xl tw-font-medium">
            {t('LANDING_LECLERC.ABOUT.TEXT_A')}
          </div>
          <div className="tw-mt-5">
            {t('LANDING_LECLERC.ABOUT.TEXT_B')}
          </div>
          <div className="tw-mt-5">
            <div className="tw-flex tw-gap-2.5">
              <img src={check} alt="Check" />
              <div className="tw-pt-1">
                {t('LANDING_LECLERC.ABOUT.TEXT_C')}
              </div>
            </div>
            <div className="tw-flex tw-gap-2.5 tw-mt-2.5">
              <img src={check} alt="Check" />
              <div className="tw-pt-1">
                {t('LANDING_LECLERC.ABOUT.TEXT_D')}
              </div>
            </div>
            <div className="tw-flex tw-gap-2.5 tw-mt-2.5">
              <img src={check} alt="Check" />
              <div className="tw-pt-1">
                {t('LANDING_LECLERC.ABOUT.TEXT_E')}
              </div>
            </div>
            <div className="tw-flex tw-gap-2.5 tw-mt-2.5">
              <img src={check} alt="Check" />
              <div className="tw-pt-1">
                {t('LANDING_LECLERC.ABOUT.TEXT_F')}
              </div>
            </div>
            <div className="tw-flex tw-gap-2.5 tw-mt-2.5">
              <img src={check} alt="Check" className="tw-self-start tw-mt-1" />
              <div className="tw-pt-1">
                {t('LANDING_LECLERC.ABOUT.TEXT_G')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
