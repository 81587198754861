const maturityInfo = {
  bilanCarbone: {
    title: 'MATURITY_INFO.CARBON_FOOTPRINT.TITLE',
    isLastBloc: false,
    levels: {
      3: {
        level: 3, // Carcle validé
        text: 'MATURITY_INFO.CARBON_FOOTPRINT.LEVELS.3.TEXT',
        badgeColor: 'noteTresForte',
        textColor: 'text-greyOne',
        cardText: 'MATURITY_INFO.CARBON_FOOTPRINT.LEVELS.3.CARD_TEXT',
        lineColor: 'bg-primaryNewBlue',
      },
      2: {
        level: 2, // Cerle a moitié rempli
        text: 'MATURITY_INFO.CARBON_FOOTPRINT.LEVELS.2.TEXT',
        badgeColor: 'noteIntermediaire',
        textColor: 'text-greyOne',
        cardText: 'MATURITY_INFO.CARBON_FOOTPRINT.LEVELS.2.CARD_TEXT',
        lineColor: 'bg-greySix',
      },
      1: {
        level: 1, // Cercle gris
        text: 'MATURITY_INFO.CARBON_FOOTPRINT.LEVELS.1.TEXT',
        badgeColor: 'noteTresInsuffisante',
        textColor: 'text-greySix',
        cardText: 'MATURITY_INFO.CARBON_FOOTPRINT.LEVELS.1.CARD_TEXT',
        lineColor: 'bg-greySix',
      },
    },
  },
  engagementsClimatiques: {
    title: 'MATURITY_INFO.CLIMATE_COMMITMENTS.TITLE',
    isLastBloc: false,
    levels: {
      5: {
        level: 3,
        text: 'MATURITY_INFO.CLIMATE_COMMITMENTS.LEVELS.5.TEXT',
        badgeColor: 'noteTresForte',
        textColor: 'text-greyOne',
        cardText: 'MATURITY_INFO.CLIMATE_COMMITMENTS.LEVELS.5.CARD_TEXT',
        lineColor: 'bg-primaryNewBlue',
      },
      4: {
        level: 2,
        text: 'MATURITY_INFO.CLIMATE_COMMITMENTS.LEVELS.4.TEXT',
        badgeColor: 'noteIntermediaire',
        textColor: 'text-greyOne',
        cardText: 'MATURITY_INFO.CLIMATE_COMMITMENTS.LEVELS.4.CARD_TEXT',
        lineColor: 'bg-primaryNewBlue',
      },
      3: {
        level: 2,
        text: 'MATURITY_INFO.CLIMATE_COMMITMENTS.LEVELS.3.TEXT',
        badgeColor: 'noteEncoreInsufissante',
        textColor: 'text-greyOne',
        cardText: 'MATURITY_INFO.CLIMATE_COMMITMENTS.LEVELS.3.CARD_TEXT',
        lineColor: 'bg-primaryNewBlue',
      },
      2: {
        level: 1,
        text: 'MATURITY_INFO.CLIMATE_COMMITMENTS.LEVELS.2.TEXT',
        badgeColor: 'noteTresInsuffisante',
        textColor: 'text-greySix',
        cardText: 'MATURITY_INFO.CLIMATE_COMMITMENTS.LEVELS.2.CARD_TEXT',
        lineColor: 'bg-greySix',
      },
      1: {
        level: 1,
        text: 'MATURITY_INFO.CLIMATE_COMMITMENTS.LEVELS.1.TEXT',
        badgeColor: 'notePasDengagement',
        textColor: 'text-greySix',
        cardText: 'MATURITY_INFO.CLIMATE_COMMITMENTS.LEVELS.1.CARD_TEXT',
        lineColor: 'bg-greySix',
      },
    },
  },
  resultatsScope1et2: {
    title: 'MATURITY_INFO.RESULTS_SCOPE_1_AND_2.TITLE',
    isLastBloc: false,
    levels: {
      6: {
        level: 3,
        text: 'MATURITY_INFO.RESULTS_SCOPE_1_AND_2.LEVELS.6.TEXT',
        badgeColor: 'noteTresForte',
        textColor: 'text-greyOne',
        cardText: 'MATURITY_INFO.RESULTS_SCOPE_1_AND_2.LEVELS.6.CARD_TEXT',
        lineColor: 'bg-primaryNewBlue',
      },
      5: {
        level: 3,
        text: 'MATURITY_INFO.RESULTS_SCOPE_1_AND_2.LEVELS.5.TEXT',
        badgeColor: 'noteForte',
        textColor: 'text-greyOne',
        cardText: 'MATURITY_INFO.RESULTS_SCOPE_1_AND_2.LEVELS.5.CARD_TEXT',
        lineColor: 'bg-primaryNewBlue',
      },
      4: {
        level: 2,
        text: 'MATURITY_INFO.RESULTS_SCOPE_1_AND_2.LEVELS.4.TEXT',
        badgeColor: 'noteIntermediaire',
        textColor: 'text-greyOne',
        cardText: 'MATURITY_INFO.RESULTS_SCOPE_1_AND_2.LEVELS.4.CARD_TEXT',
        lineColor: 'bg-greySix',
      },
      3: {
        level: 2,
        text: 'MATURITY_INFO.RESULTS_SCOPE_1_AND_2.LEVELS.3.TEXT',
        badgeColor: 'noteEncoreInsufissante',
        textColor: 'text-greyOne',
        cardText: 'MATURITY_INFO.RESULTS_SCOPE_1_AND_2.LEVELS.3.CARD_TEXT',
        lineColor: 'bg-greySix',
      },
      2: {
        level: 1,
        text: 'MATURITY_INFO.RESULTS_SCOPE_1_AND_2.LEVELS.2.TEXT',
        badgeColor: 'noteTresInsuffisante',
        textColor: 'text-greySix',
        cardText: 'MATURITY_INFO.RESULTS_SCOPE_1_AND_2.LEVELS.2.CARD_TEXT',
        lineColor: 'bg-greySix',
      },
      1: {
        level: 1,
        text: 'MATURITY_INFO.RESULTS_SCOPE_1_AND_2.LEVELS.1.TEXT',
        badgeColor: 'notePasDengagement',
        textColor: 'text-greySix',
        cardText: 'MATURITY_INFO.RESULTS_SCOPE_1_AND_2.LEVELS.1.CARD_TEXT',
        lineColor: 'bg-greySix',
      },
    },
  },
  resultatsScope3: {
    title: 'MATURITY_INFO.RESULTS_SCOPE_3.TITLE',
    isLastBloc: true,
    levels: {
      6: {
        level: 3,
        text: 'MATURITY_INFO.RESULTS_SCOPE_3.LEVELS.6.TEXT',
        badgeColor: 'noteTresForte',
        textColor: 'text-greyOne',
        cardText: 'MATURITY_INFO.RESULTS_SCOPE_3.LEVELS.6.CARD_TEXT',
        lineColor: 'bg-primaryNewBlue',
      },
      5: {
        level: 3,
        text: 'MATURITY_INFO.RESULTS_SCOPE_3.LEVELS.5.TEXT',
        badgeColor: 'noteForte',
        textColor: 'text-greyOne',
        cardText: 'MATURITY_INFO.RESULTS_SCOPE_3.LEVELS.5.CARD_TEXT',
        lineColor: 'bg-primaryNewBlue',
      },
      4: {
        level: 2,
        text: 'MATURITY_INFO.RESULTS_SCOPE_3.LEVELS.4.TEXT',
        badgeColor: 'noteIntermediaire',
        textColor: 'text-greyOne',
        cardText: 'MATURITY_INFO.RESULTS_SCOPE_3.LEVELS.4.CARD_TEXT',
        lineColor: 'bg-greySix',
      },
      3: {
        level: 2,
        text: 'MATURITY_INFO.RESULTS_SCOPE_3.LEVELS.3.TEXT',
        badgeColor: 'noteEncoreInsufissante',
        textColor: 'text-greyOne',
        cardText: 'MATURITY_INFO.RESULTS_SCOPE_3.LEVELS.3.CARD_TEXT',
        lineColor: 'bg-greySix',
      },
      2: {
        level: 1,
        text: 'MATURITY_INFO.RESULTS_SCOPE_3.LEVELS.2.TEXT',
        badgeColor: 'noteTresInsuffisante',
        textColor: 'text-greySix',
        cardText: 'MATURITY_INFO.RESULTS_SCOPE_3.LEVELS.2.CARD_TEXT',
        lineColor: 'bg-greySix',
      },
      1: {
        level: 1,
        text: 'MATURITY_INFO.RESULTS_SCOPE_3.LEVELS.1.TEXT',
        badgeColor: 'notePasDengagement',
        textColor: 'text-greySix',
        cardText: 'MATURITY_INFO.RESULTS_SCOPE_3.LEVELS.1.CARD_TEXT',
        lineColor: 'bg-greySix',
      },
    },
  },
};

export default maturityInfo;
