import React from 'react';
import { useTranslation } from 'react-i18next';

import dropdownArrow from '../../../../assets/ui/caret-down-s.svg';

function FilterDropdownButton({ isOpen, onToggle }) {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className="tw-relative tw-max-h-10 tw-h-10 tw-border tw-border-solid tw-rounded-lg tw-border-greySix tw-text-greyFour tw-text-xs tw-px-4 filter-select tw-w-full sm:tw-w-56 tw-flex tw-justify-between tw-items-center"
      onClick={onToggle}
    >
      <div className="tw-transition-all tw-duration-600 tw-flex tw-flex-col tw-items-start">
        <div className="tw-transition-all tw-duration-600 tw-text-greyFour">
          {t('PARTNER_DASHBOARD.STATISTICS.FILTER.PLACEHOLDER')}
        </div>
      </div>
      <img
        src={dropdownArrow}
        className={`${isOpen ? 'tw-rotate-180' : ''} tw-duration-500 tw-transition-all`}
        alt="Dropdown arrow"
      />
    </button>
  );
}

export default FilterDropdownButton;
