import React from 'react';
import { useTranslation } from 'react-i18next';

import spin from '../../../assets/ui/spin.svg';

import getLocalLink from '../../../utils/getLocalLink';

function GetRecontactedForm({ handleSubmit, onSubmitSecondForm, register, errors, loading, setGetRecontacted }) {
  const { t } = useTranslation();

  return (
    <form
      onSubmit={handleSubmit(onSubmitSecondForm)}
      className="tw-bg-white tw-pt-[30px] xl:tw-w-[640px] xl:tw-right-[80px] xl:tw-absolute tw-rounded-lg xl:tw-rounded-xl xl:tw-border xl:tw-border-solid xl:tw-border-greySix xl:tw-px-10 xl:tw-pt-[30px] xl:tw-pb-[30px] tw-text-greyOne tw-px-[30px] tw-pb-[30px] tw-mb-[60px] xl:tw-mb-0"
    >
      <h5 className="tw-text-xl tw-font-normal text-center tw-text-greyOne">
        {t('LANDING_LECLERC.FORM.TEXT_L')}
      </h5>
      <div className="tw-flex tw-gap-2.5 tw-mt-[30px] tw-flex-col xl:tw-flex-row">
        <div className="xl:tw-w-1/2 tw-text-sm">
          <label htmlFor="firstname" className="tw-text-greyOne">
            {t('LANDING_LECLERC.FORM.INPUT.FIRSTNAME.LABEL')}
          </label>
          <input
            type="text"
            id="firstname"
            name="firstname"
            className={`tw-w-full tw-rounded-md tw-border tw-border-solid tw-px-3.5 tw-py-3 tw-mt-1 tw-text-greyThree tw-bg-greySeven ${errors.firstname ? 'tw-border-redError focus:tw-outline-redError' : 'tw-border-greySix focus:tw-outline-secondarySkyBlue'}`}
            {...register('firstname', {
              required: t('LANDING_LECLERC.FORM.INPUT.FIRSTNAME.REQUIRED'),
            })}
          />
          {errors && errors.firstname && (
          <div className="tw-mt-1.5 tw-text-sm tw-text-redError">{errors.firstname?.message}</div>
          )}
        </div>
        <div className="xl:tw-w-1/2 tw-text-sm">
          <label htmlFor="lastname" className="tw-text-greyOne">
            {t('LANDING_LECLERC.FORM.INPUT.LASTNAME.LABEL')}
          </label>
          <input
            type="text"
            id="lastname"
            name="lastname"
            className={`tw-w-full tw-rounded-md tw-border tw-border-solid tw-px-3.5 tw-py-3 tw-mt-1 tw-text-greyThree tw-bg-greySeven ${errors.lastname ? 'tw-border-redError focus:tw-outline-redError' : 'tw-border-greySix focus:tw-outline-secondarySkyBlue'}`}
            {...register('lastname', {
              required: t('LANDING_LECLERC.FORM.INPUT.LASTNAME.REQUIRED'),
            })}
          />
          {errors && errors.lastname && (
          <div className="tw-mt-1.5 tw-text-sm tw-text-redError">{errors.lastname?.message}</div>
          )}
        </div>
      </div>
      <div className="tw-flex tw-gap-2.5 tw-mt-6 tw-flex-col xl:tw-flex-row">
        <div className="xl:tw-w-1/2 tw-text-sm">
          <label htmlFor="job" className="tw-text-greyOne">
            {t('LANDING_LECLERC.FORM.INPUT.JOB.LABEL')}
          </label>
          <input
            type="text"
            id="job"
            name="job"
            className={`tw-w-full tw-rounded-md tw-border tw-border-solid  tw-px-3.5 tw-py-3 tw-mt-1 tw-text-greyThree tw-bg-greySeven ${errors.job ? 'tw-border-redError focus:tw-outline-redError' : 'tw-border-greySix focus:tw-outline-secondarySkyBlue'}`}
            {...register('job', {
              required: t('LANDING_LECLERC.FORM.INPUT.JOB.REQUIRED'),
            })}
          />
          {errors && errors.job && (
          <div className="tw-mt-1.5 tw-text-sm tw-text-redError">{errors.job?.message}</div>
          )}
        </div>
        <div className="xl:tw-w-1/2 tw-text-sm">
          <label htmlFor="phone" className="tw-text-greyOne">
            {t('LANDING_LECLERC.FORM.INPUT.PHONE.LABEL')}<span className="tw-text-[10px] tw-text-greyThree">{t('LANDING_LECLERC.FORM.OPTIONAL')}</span>
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            className={`tw-w-full tw-rounded-md tw-border tw-border-solid  tw-px-3.5 tw-py-3 tw-mt-1 tw-text-greyThree tw-bg-greySeven ${errors.phone ? 'tw-border-redError focus:tw-outline-redError' : 'tw-border-greySix focus:tw-outline-secondarySkyBlue'}`}
            {...register('phone', {
              required: false,
              pattern: {
                value: /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/,
                message: t('LANDING_LECLERC.FORM.INPUT.PHONE.INVALID'),
              },
            })}
          />
          {errors && errors.phone && (
          <div className="tw-mt-1.5 tw-text-sm tw-text-redError">{errors.phone?.message}</div>
          )}
        </div>
      </div>
      <div className="tw-mt-5">
        <label htmlFor="message" className="tw-text-greyOne">
          {t('LANDING_LECLERC.FORM.INPUT.MESSAGE.LABEL')}<span className="tw-text-[10px] tw-text-greyThree">{t('LANDING_LECLERC.FORM.OPTIONAL')}</span>
        </label>
        <div>
          <textarea
            name="message"
            id="message"
            className={`tw-w-full tw-rounded-md tw-border tw-border-solid  tw-px-3.5 tw-py-3 tw-mt-1 tw-text-greyThree tw-bg-greySeven ${errors.message ? 'tw-border-redError focus:tw-outline-redError' : 'tw-border-greySix focus:tw-outline-secondarySkyBlue'}`}
            rows={5}
            {...register('message', {
              required: false,
            })}
          />
          {errors && errors.message && (
          <div className="tw-mt-1.5 tw-text-sm tw-text-redError">{errors.message?.message}</div>
          )}
        </div>
      </div>
      <div className="tw-mt-3 tw-text-xs tw-text-greyOne">
        {t('LANDING_LECLERC.FORM.TEXT_H')}
        <a href={`${getLocalLink('/invitation-adhesion-fournisseurs/politique-de-confidentialite')}`} className="tw-no-underline tw-font-medium tw-text-secondarySkyBlue" rel="nofollow noreferrer" target="blank">
          {t('LANDING_LECLERC.FORM.TEXT_I')}
        </a>
      </div>
      <div className="tw-flex tw-justify-between tw-items-center tw-gap-5 tw-mt-7">
        <div>
          <button type="button" className="tw-border-0 tw-bg-transparent tw-px-0" onClick={() => setGetRecontacted(false)}>
            {t('LANDING_LECLERC.FORM.BACK')}
          </button>
        </div>
        <div className="">
          <button type="submit" disabled={loading} className={`tw-w-full tw-py-3 tw-font-medium tw-bg-primaryNewBlue tw-text-white tw-border-0 tw-rounded-md tw-px-8 hover:tw-bg-primaryNewBlueHover ${loading ? 'disabled' : ''}`}>
            {loading ? <img src={spin} alt="spin" /> : t('LANDING_LECLERC.FORM.SEND')}
          </button>
        </div>
      </div>
    </form>
  );
}

export default GetRecontactedForm;
